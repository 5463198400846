import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    buildPropertiesByLanguage,
    buildFormFieldsByLanguage,
    buildTableColumnsByLanguage,
} from '@/services/language-utils';
import {
    DELIVERY_FLAG_OPTIONS,
    IMPORT_TEMPLATE_URL,
    OFFLINE_DELIVERY_TYPE_OPTIONS,
} from '@/constants';
import { createPageLayout, flexibleFilterForm, formFooter, tableMixin } from '../mixin';
import { recordImportLogs } from '@/services';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export const config: Config = {
    entities: {
        productPickupLimit: {
            apiPath: '/loader/dc-goods/admin/product/pick/limit',
            properties: {
                ...buildPropertiesByLanguage('name', {
                    type: 'string',
                    displayName: '<<productPickupLimit.goodsName>>',
                }),
                sku: {
                    type: 'string',
                    displayName: '<<productPickupLimit.sku>>',
                },
                freightType: {
                    type: 'string.options.radio',
                    displayName: '<<productPickupLimit.pickupLimit>>',
                    options: OFFLINE_DELIVERY_TYPE_OPTIONS,
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<productPickupLimit.merchant>>',
                },
                merchantFreightType: {
                    type: 'array.idArray.select',
                    displayName: '<<productPickupLimit.merchantPickupMethods>>',
                    options: DELIVERY_FLAG_OPTIONS,
                },
            },
            filters: {
                keyWord: {
                    type: 'string',
                    displayName: '<<productPickupLimit.keyword>>',
                },
                sku: {
                    type: 'string',
                    displayName: '<<productPickupLimit.sku>>',
                    controlConfig: {
                        placeholder: '<<cskuPlaceHolder>>',
                    },
                },
                merchantId: {
                    type: 'string.options.select',
                    displayName: '<<productPickupLimit.merchant>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        allowClear: true,
                    },
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant/byUser',
                    },
                },
                freightType: {
                    type: 'string.options.select',
                    displayName: '<<productPickupLimit.pickupLimit>>',
                    options: OFFLINE_DELIVERY_TYPE_OPTIONS,
                    controlConfig: {
                        placeholder: '<<defaultPlaceholderSelect>>',
                        allowClear: true,
                    },
                },
                freightTypeConflict: {
                    type: 'string.options.select',
                    displayName: '<<productPickupLimit.pickupConflict>>',
                    options: [
                        {
                            name: language.getText('common.yes'),
                            id: 'true',
                        },
                        {
                            name: language.getText('common.no'),
                            id: 'false',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<defaultPlaceholderSelect>>',
                        allowClear: true,
                    },
                },
            },
        },
    },
    components: {
        ProductPickupLimitPage: {
            entity: 'productPickupLimit',
            ...createPageLayout([
                {
                    component: 'ProductPickupLimitFilter',
                },
                {
                    component: 'ProductPickupLimitButtons',
                },
                {
                    component: 'ProductPickupLimitTable',
                    privilege: {
                        path: 'goods.product_pick_limit.product_limit_list',
                        level: 'full',
                    },
                },
            ]),
        },
        ProductPickupLimitFilter: {
            ...flexibleFilterForm,
            fields: [
                {
                    property: 'keyWord',
                },
                {
                    property: 'sku',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'freightType',
                },
                {
                    property: 'freightTypeConflict',
                },
            ],
        },
        ProductPickupLimitButtons: {
            component: 'FlexLayout',
            direction: 'horizontal',
            style: {
                display: 'block',
                marginBottom: 15,
            },
            items: [
                {
                    component: 'TableBatchButton',
                    type: 'primary',
                    text: '<<productPickupLimit.batchDeletion>>',
                    style: {
                        marginRight: 15,
                    },
                    method: 'delete',
                    rowKeysParamProp: 'ids',
                    source: {
                        apiPath: '/dc-goods/admin/product/pick/limit/batchDelete',
                    },
                    privilege: {
                        path: 'goods.product_pick_limit.product_limit_batch_delete',
                        level: 'full',
                    },
                },
                {
                    component: 'Upload',
                    apiPath: '/dc-file/excels?location_id=2',
                    showSuccessMessage: false,
                    className: 'upload-link',
                    triggerComponent: {
                        component: 'Button',
                        icon: 'upload',
                        text: '<<productPickupLimit.importPickupLimit>>',
                        style: {
                            marginRight: 15,
                        },
                    },
                    onUploadComplete(response: any) {
                        if (response) {
                            recordImportLogs({
                                name: services.language.getText('productPickupLimit.title'),
                                apiPath:
                                    '/dc-business-data/admin/excel/import/SKU_PICK_METHOD_RESTRICTIONS',
                                params: {
                                    resourceId: response.id,
                                },
                            });
                        }
                    },
                    privilege: {
                        path: 'goods.product_pick_limit.product_limit_import',
                        level: 'full',
                    },
                },
                {
                    component: 'OSSExportButton',
                    text: '<<productPickupLimit.downloadImportTemp>>',
                    type: 'link',
                    style: {
                        marginRight: 15,
                    },
                    url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.PRODUCT_PICKUP_LIMIT}`,
                },
                {
                    component: 'ExportButtonWithFilter',
                    text: '<<productPickupLimit.exportPickupLimit>>',
                    icon: 'download',
                    style: {
                        marginRight: 15,
                    },
                    source: {
                        apiPath: `/dc-goods/admin/product/pick/limit/export/take`,
                    },
                    range: {
                        apiPath: '/dc-goods/admin/product/pick/limit/count',
                        perPage: 100000,
                    },
                    isAsync: true,
                    showSuccessTips: true,
                    showErrorMessage: true,
                    privilege: {
                        path: 'goods.product_pick_limit.product_limit_export',
                        level: 'full',
                    },
                },
            ],
        },
        ProductPickupLimitTable: {
            ...tableMixin,
            rowSelection: {
                onChange() {},
            },
            columns: [
                ...buildTableColumnsByLanguage({
                    property: 'name',
                }),
                {
                    property: 'sku',
                },
                {
                    property: 'freightType',
                },
                {
                    property: 'merchantName',
                },
                {
                    property: 'merchantFreightType',
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/product/pickup-limit/edit/{{row.id}}',
                        privilege: {
                            path: 'goods.product_pick_limit.product_limit_update',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<common.delete>>',
                        config: {
                            actionType: 'delete',
                            source: {
                                apiPath: '/dc-goods/admin/product/pick/limit/delete',
                            },
                        },
                        privilege: {
                            path: 'goods.product_pick_limit.product_limit_delete',
                            level: 'full',
                        },
                    },
                ],
            },
        },
        ProductPickupLimitEditPage: {
            entity: 'productPickupLimit',
            component: 'Card',
            loaderType: 'get',
            content: {
                component: 'ModifyFormPlus',
                className: 'sub-form',
                labelCol: 6,
                controlCol: 8,
                footer: formFooter,
                submitSuccessBehavior: {
                    route: 'goBack',
                },
                fields: [
                    ...buildFormFieldsByLanguage({
                        property: 'name',
                        controlConfig: {
                            disabled: true,
                        },
                    }),
                    {
                        property: 'sku',
                        controlConfig: {
                            disabled: true,
                        },
                    },
                    {
                        property: 'merchantName',
                        controlConfig: {
                            disabled: true,
                        },
                    },
                    {
                        property: 'merchantFreightType',
                        controlConfig: {
                            disabled: true,
                        },
                    },
                    {
                        property: 'freightType',
                    },
                ],
            },
        },
    },
};

import { cloneDeep } from 'lodash';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { findIndex } from 'lodash';
import {
    tableMixin,
    multipleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
} from '../mixin';
import { recordImportLogs } from '@/services';
import { services } from '@comall-backend-builder/core';
import { PRODUCT_LIST_TABLE_ALL } from '@/configs/pro-table-constants';
import { SOURCE_TYPE_OPTIONS, SPECS_TYPE_OPTIONS, IMPORT_TEMPLATE_URL } from '@/constants';

type ComponentName = 'ProductList' | 'VirtualProductList' | 'GroupProductList';
type RouterName = 'virtual' | 'normal' | 'group';

enum RouterType {
    'virtual' = 3,
    'normal' = 0,
    'group' = 2,
}
export enum ComponentName2RouterName {
    'ProductList' = 'normal',
    'VirtualProductList' = 'virtual',
    'GroupProductList' = 'group',
}

enum ComponentName2Privilege {
    'ProductList' = 'goods',
    'VirtualProductList' = 'virtual_goods_manage',
    'GroupProductList' = 'group_goods',
}
const entityProperties = {
    pictureUrl: {
        type: 'string.image.text',
        displayName: '<<productList.heroImagePath>>',
    },
    specsType: {
        type: 'string.options.radio',
        displayName: '<<productList.specsType>>',
        options: SPECS_TYPE_OPTIONS,
    },
    modeType: {
        type: 'string',
        displayName: '<<productList.modeType>>',
    },
    merchantName: {
        type: 'string',
        displayName: '<<productList.merchantName>>',
    },
    salesChannels: {
        type: 'string',
        displayName: '<<productList.salesChannels>>',
    },
    weight: {
        type: 'string',
        displayName: '<<productList.productWeight>>',
    },
    volume: {
        type: 'string',
        displayName: '<<productList.productVolume>>',
    },
    name: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'productList.productName') },
    },
    csku: {
        type: 'string',
        displayName: '<<productList.csku>>',
    },
    barcode: {
        type: 'string',
        displayName: '<<productList.barCode>>',
    },
    brandName: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'productList.brandName') },
    },
    offlineCategoryName: {
        type: 'string.format',
        displayName: '<<productList.saleCategory>>',
    },
    sourceType: {
        type: 'string.options.select',
        displayName: '<<productList.productSource>>',
        options: SOURCE_TYPE_OPTIONS,
    },
};

const entityFilters = {
    keyword: {
        type: 'string',
        displayName: '<<keyword>>',
    },
    csku: {
        type: 'string',
        displayName: '<<productList.csku>>',
    },
    offlineCategoryId: {
        type: 'array.treeSelect.async',
        displayName: '<<displayCategory.saleCategories>>',
        options: [],
        source: {
            apiPath: '/loader/dc-goods/admin/offline_categories/tree_no_root',
        },
        params: { id: '-2' },
        rootId: '-2',
        controlConfig: {
            placeholder: '<<defaultPlaceholderSelect>>',
            allowClear: true,
        },
    },
    sourceType: {
        type: 'string.options.select',
        displayName: '<<productList.productSource>>',
        options: SOURCE_TYPE_OPTIONS,
    },
    brandId: {
        type: 'array.options.autoComplete',
        displayName: '<<productList.brand>>',
        options: [],
        source: {
            apiPath: '/loader/dc-goods/admin/brands/list',
        },
    },
    hasPicture: {
        type: 'string.options.select',
        displayName: '<<productList.hasPicture>>',
        options: [
            { id: 'false', name: services.language.getText('productList.no') },
            { id: 'true', name: services.language.getText('productList.yes') },
        ],
    },
    merchantId: {
        type: 'string.options.select',
        displayName: '<<productList.merchantName>>',
        options: [],
        controlConfig: {
            placeholder: '<<common.placeSelect>>',
        },
        source: {
            apiPath: '/loader/dc-goods/admin/merchant/byUser',
        },
    },
};
const filterProperties = [
    {
        property: 'keyword',
        controlConfig: {
            allowClear: true,
            placeholder: '<<productList.keywordPlaceholder>>',
        },
    },
    {
        property: 'csku',
        controlConfig: {
            allowClear: true,
            placeholder: '<<productList.cskuPlaceHolder>>',
        },
    },
    {
        property: 'brandId',
        controlConfig: {
            showArrow: true,
            showSearch: true,
            allowClear: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'offlineCategoryId',
        controlConfig: {
            allowClear: true,
            multiple: false,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'sourceType',
        controlConfig: {
            showSearch: true,
            allowClear: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'hasPicture',
        controlConfig: {
            allowClear: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    {
        property: 'merchantId',
        controlConfig: {
            allowClear: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
];
const getFiltersProperty = (type: RouterName) => {
    let filter = cloneDeep(filterProperties);
    //@ts-ignore
    if (type !== RouterType[0]) {
        let index = findIndex(filter, (i: any) => i.property === 'sourceType');
        filter.splice(index, 1);
    }
    return filter;
};

const tableConfig = (type: ComponentName) => {
    let filter = cloneDeep([
        {
            property: 'pictureUrl',
            width: 150,
            displayConfig: {
                style: {
                    display: 'block',
                    width: 50,
                    height: 50,
                    border: '1px solid #eee',
                    overflow: 'hidden',
                },
            },
        },
        { property: 'csku' },
        { property: 'barcode', show: false },
        ...getLanguageColumns('name'),
        ...getLanguageColumns('brandName'),
        {
            property: 'offlineCategoryName',
            displayConfig: { format: (val: any[]) => (val || []).join('/') },
        },
        { property: 'sourceType' },
        { property: 'weight' },
        { property: 'volume' },
        { property: 'specsType' },
        { property: 'modeType' },
        { property: 'merchantName' },
        { property: 'salesChannels' },
    ]);
    //@ts-ignore
    if (type === 'VirtualProductList') {
        filter = filter.filter((i: any) => ['weight', 'volume'].indexOf(i.property) < 0);
    }

    if (type !== 'ProductList') {
        filter = filter.filter((i: any) => ['sourceType'].indexOf(i.property) < 0);
    }

    return {
        ...tableMixin,
        componentName: PRODUCT_LIST_TABLE_ALL,
        component: 'ProTable',
        scroll: {
            x: 'max-content',
        },
        columns: filter,
        actionColumn: {
            title: '<<actions>>',
            fixed: 'right',
            items: [
                {
                    text: '<<view>>',
                    type: 'component',
                    component: 'TableActionStateful',
                    config: {
                        actionType: 'link',
                        renderConfig: (row: any, props: any) => {
                            return {
                                path: `/productList/${ComponentName2RouterName[type]}/detail/${row.id}`,
                            };
                        },
                    },
                    privilege: {
                        path: `goods.${ComponentName2Privilege[type]}.view_detail`,
                        level: 'full',
                    },
                },
                {
                    text: '<<edit>>',
                    type: 'component',
                    component: 'TableActionStateful',
                    config: {
                        actionType: 'link',
                        renderConfig: (row: any, props: any) => {
                            return {
                                path: `/productList/${ComponentName2RouterName[type]}/edit/${row.id}`,
                            };
                        },
                    },
                    privilege: {
                        path: `goods.${ComponentName2Privilege[type]}.edit`,
                        level: 'full',
                    },
                },
            ],
        },
    };
};
const createButtons = (type: ComponentName) => {
    return {
        component: 'FlexLayout',
        direction: 'horizontal',
        style: {
            display: 'block',
            marginBottom: 15,
        },
        items: [
            {
                component: 'Button',
                text: `<<productList.${ComponentName2RouterName[type]}.add>>`,
                icon: 'plus',
                type: 'primary',
                route: `/productList/${ComponentName2RouterName[type]}/add`,
                style: {
                    marginRight: 15,
                },
                privilege: {
                    path: `goods.${ComponentName2Privilege[type]}.add`,
                    level: 'full',
                },
            },
            {
                component: 'ExportButtonWithFilter',
                text: '<<productList.exportProductList>>',
                icon: 'download',
                style: {
                    marginRight: 15,
                },
                source: {
                    apiPath: `/dc-goods/admin/spu_info_records/${ComponentName2RouterName[type]}/export/take`,
                },
                range: {
                    apiPath: '/dc-goods/admin/spu_info_records/export/count',
                    perPage: 100000,
                },
                formatter: (params: any) => {
                    const { brandId, offlineCategoryId, ...rest } = params;
                    if (brandId) {
                        rest.brandId = brandId.map((item: any) => item.id).join(',');
                    }
                    if (offlineCategoryId) {
                        rest.offlineCategoryId = offlineCategoryId.id;
                    }
                    if (!rest.sourceType && type) {
                        if (RouterType[ComponentName2RouterName[type]] + '')
                            rest.sourceType = RouterType[ComponentName2RouterName[type]];
                    }
                    return rest;
                },
                isAsync: true,
                showSuccessTips: true,
                showErrorMessage: true,
                privilege: {
                    path: `goods.${ComponentName2Privilege[type]}.export_goods_detail`,
                    level: 'full',
                },
            },
            {
                component: `Import${type}Button`,
                privilege: {
                    path: `goods.${ComponentName2Privilege[type]}.import_goods_detail`,
                    level: 'full',
                },
            },
            {
                component: 'OSSExportButton',
                text: '<<productList.downloadTemplate>>',
                type: 'link',
                privilege: {
                    path: `goods.${ComponentName2Privilege[type]}.download_import_template`,
                    level: 'full',
                },
                url: `${ENV.IMPORT_TEMPLATE_ROOT}/excels/template/${IMPORT_TEMPLATE_URL.PRODUCT_DETAILS}`,
            },
            {
                component: 'ImportButton',
                privilege: {
                    path: `goods.${ComponentName2Privilege[type]}.import_goods_image`,
                    level: 'full',
                },
                directory: true,
                buttons: {
                    text: '<<productList.importProductPictures>>',
                    icon: 'upload',
                    style: {
                        marginLeft: 15,
                    },
                },
                beforeUpload: (file: any, fileList: any) => {
                    fileList.forEach((item: any) => {
                        item.groupId = new Date().getTime();
                        return item;
                    });
                    if (
                        ['image/png', 'image/jpg', 'image/svg', 'image/jpeg'].indexOf(file.type) < 0
                    ) {
                        return false;
                    }
                },
                customRequest: async (fileInfo: any) => {
                    const file = fileInfo.file;
                    let data: any = {
                        files: file,
                    };
                    let config: any = {
                        progressCallBack: (percent: number) => {
                            fileInfo.onProgress && fileInfo.onProgress({ percent: percent }, file);
                        },
                        fileName: 'file',
                        apiRoot: '',
                        apiPath: `/dc-goods/admin/spu_info_records/${ComponentName2RouterName[type]}/uploadImages?group_Id=
                            ${file.groupId}`,
                    };

                    try {
                        const response = await services.api.upload(data, config);
                        fileInfo.onSuccess({ id: response.id, url: response.path }, file);
                    } catch (e) {
                        fileInfo.onError(e);
                    }
                },
            },
            {
                component: 'ExportButtonWithFilter',
                text: '<<productList.exportAttr>>',
                icon: 'download',
                style: {
                    marginLeft: 15,
                },
                source: {
                    apiPath: `/dc-goods/admin/spu_info_records/${ComponentName2RouterName[type]}/attrExport/take`,
                },
                range: {
                    apiPath: '/dc-goods/admin/spu_info_records/export/attr/count',
                    perPage: 100000,
                },
                formatter: (params: any) => {
                    const { brandId, offlineCategoryId, ...rest } = params;
                    if (brandId) {
                        rest.brandId = brandId.map((item: any) => item.id).join(',');
                    }
                    if (offlineCategoryId) {
                        rest.offlineCategoryId = offlineCategoryId.id;
                    }
                    if (!rest.sourceType && type) {
                        if (RouterType[ComponentName2RouterName[type]] + '')
                            rest.sourceType = RouterType[ComponentName2RouterName[type]];
                    }
                    return rest;
                },
                isAsync: true,
                showSuccessTips: true,
                showErrorMessage: true,
                privilege: {
                    path: `goods.${ComponentName2Privilege[type]}.export_goods_attr`,
                    level: 'full',
                },
            },
            {
                component: `Import${type}AttrButton`,
                privilege: {
                    path: `goods.${ComponentName2Privilege[type]}.import_goods_attr`,
                    level: 'full',
                },
            },
        ],
    };
};

const getProductComponent = (type: ComponentName) => {
    return {
        [`${type}Container`]: {
            component: 'Viewport',
        },
        [`${type}Management`]: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [{ component: type }],
            },
        },
        [`${type}`]: {
            entity: 'productList',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: `${type}Filter`,
                    privilege: {
                        path: `goods.${ComponentName2Privilege[type]}.view_list`,
                        level: 'full',
                    },
                },
                { component: `${type}Button` },
                {
                    component: `${type}Table`,
                    privilege: {
                        path: `goods.${ComponentName2Privilege[type]}.view_list`,
                        level: 'full',
                    },
                },
            ],
        },
        [`Import${type}Button`]: {
            component: 'Upload',
            apiPath: '/dc-file/excels?location_id=2',
            showSuccessMessage: false,
            className: 'upload-link',
            triggerComponent: {
                component: 'Button',
                icon: 'upload',
                text: '<<productList.importProductList>>',
                style: {
                    marginRight: 15,
                },
            },
            onUploadComplete(response: any) {
                if (response) {
                    recordImportLogs({
                        name: services.language.getText('productList.productInfo'),
                        apiPath: `/dc-goods/admin/spu_info_records/${
                            ComponentName2RouterName[type]
                        }/${RouterType[ComponentName2RouterName[type]]}/up_spu_info_excel`,
                        params: { resourceId: response.id },
                    });
                }
            },
        },
        [`Import${type}AttrButton`]: {
            component: 'Upload',
            apiPath: '/dc-file/excels?location_id=2',
            showSuccessMessage: false,
            className: 'upload-link',
            triggerComponent: {
                component: 'Button',
                icon: 'upload',
                text: '<<productList.importAttr>>',
                style: {
                    marginLeft: 15,
                },
            },
            onUploadComplete(response: any) {
                if (response) {
                    recordImportLogs({
                        name: services.language.getText('productList.productInfo'),
                        apiPath: `/dc-goods/admin/spu_info_records/${
                            ComponentName2RouterName[type]
                        }/${RouterType[ComponentName2RouterName[type]]}/up_sku_attr_excel`,
                        params: { resourceId: response.id },
                    });
                }
            },
        },
        [`${type}Button`]: createButtons(type),
        [`${type}Filter`]: {
            ...multipleFilterForm,
            labelCol: {},
            className: 'global-flexible-filter-form',
            submit: {
                text: '<<query>>',
            },
            reset: true,
            simple: {
                fieldCol: {
                    span: 8,
                },
                fields: ['keyword', 'offlineCategoryId', 'brandId'],
            },
            fields: getFiltersProperty(ComponentName2RouterName[type]),
        },
        [`${type}Table`]: tableConfig(type),
    };
};
export const config: Config = {
    entities: {
        productList: {
            apiPath: '/loader/dc-goods/admin/cskus',
            properties: cloneDeep(entityProperties),
            filters: entityFilters,
        },
    },
    components: {
        ...getProductComponent('ProductList'),
        ...getProductComponent('VirtualProductList'),
        ...getProductComponent('GroupProductList'),
    },
};

import { services } from '@comall-backend-builder/core/lib';
import { isEmpty } from 'lodash';
import { getStoreInfo } from '@/services/utils';

const roleTypeMap = {
    1: 'store',
    2: 'merchant',
};
const baseLoader = (roleType: 1 | 2) => {
    return {
        get: async (data: any, config: any) => {
            const result: any = await services.api.get(data, {
                ...config,
                apiPath: `/dc-goods/admin/store/freight/getFreightDetail`,
            });

            if (result.businessRulesVo === null) {
                result.businessRulesVo = [];
            }
            if (result.regionExtraFreightVo === null) {
                result.regionExtraFreightVo = [];
            }
            if (result.businessRulesVo.length === 0) {
                result.businessRulesVo.push({
                    startWeek: 'MONDAY',
                    endWeek: 'SUNDAY',
                    startTime: '10:00',
                    endTime: '22:00',
                    quantity: 0,
                });
            }
            const weightStepFreigh = {
                startingWeight: result.startingWeight,
                stepWeight: result.stepWeight,
                stepFreight: result.stepFreight,
            };
            const res = { ...result, weightStepFreigh };
            return res;
        },
        post: async (data: any, config: any) => {
            const params = {
                ...data,
                onlineStoreId: data.onlineStoreSelect,
                baseFreight: data.baseFreight,
                businessRules: data.businessRulesVo,
                earliestDeliveryDate: data.earliestDeliveryDate,
                cutOrderTime: data.cutOrderTime,
                latestDeliveryDate: data.latestDeliveryDate,
                minConsumeAmount: data.minConsumeAmount,
                regionExtraFreight: data.regionExtraFreightVo,
                unsatisfiedBaseFreight: data.unsatisfiedBaseFreight,
                startingWeight: data.weightStepFreigh.startingWeight,
                stepWeight: data.weightStepFreigh.stepWeight,
                stepFreight: data.weightStepFreigh.stepFreight,
                roleType: roleType,
                merchantId: data.merchantSelect,
            };
            return await services.api.post(params, {
                ...config,
                apiPath: `/dc-goods/admin/store/freight/${roleTypeMap[roleType]}/addFreightRule`,
            });
        },
        put: async (data: any, config: any) => {
            const params = {
                ...data,
                id: data.id,
                baseFreight: data.baseFreight,
                businessRules: data.businessRulesVo,
                earliestDeliveryDate: data.earliestDeliveryDate,
                cutOrderTime: data.cutOrderTime,
                latestDeliveryDate: data.latestDeliveryDate,
                minConsumeAmount: data.minConsumeAmount,
                regionExtraFreight: data.regionExtraFreightVo,
                unsatisfiedBaseFreight: data.unsatisfiedBaseFreight,
                startingWeight: data.weightStepFreigh.startingWeight,
                stepWeight: data.weightStepFreigh.stepWeight,
                stepFreight: data.weightStepFreigh.stepFreight,
                merchantId: data.merchantSelect,
            };
            return await services.api.put(params, {
                ...config,
                apiPath: `/dc-goods/admin/store/freight/${roleTypeMap[roleType]}/editFreightRule`,
            });
        },
    };
};

export default [
    {
        //编辑
        key: '/dc-goods/admin/store/freight/edit',
        loader: baseLoader(1),
    },
    { key: '/dc-goods/admin/merchant/freight/edit', loader: baseLoader(2) },
    {
        key: '/dc-goods/admin/merchant',
        loader: {
            get: async (data: any, config: any) => {
                const response: any = await services.api.get(
                    { ...data, page: 1, perPage: 20 },
                    config
                );
                let result = response.result.map((item: any) => ({
                    id: item.id,
                    name: item.nameMap.zh,
                }));
                return result;
            },
        },
    },
    {
        key: '/dc-goods/admin/merchant/byUser',
        loader: {
            get: async (data: any, config: any) => {
                const response: any = await services.api.get(
                    { ...data, page: 1, perPage: 20 },
                    config
                );
                let result = response.result.map((item: any) => ({
                    id: item.id,
                    name: item.nameMap.zh,
                }));
                return result;
            },
        },
    },
    {
        key: '/base-order/admin/payment_type',
        loader: {
            get: async (data: any, config: any) => {
                const response: any = await services.api.get({ ...data }, config);
                const result = Object.keys(response).map((i: any) => ({
                    name: i,
                    id: response[i],
                }));
                return result;
            },
        },
    },
    {
        key: '/base-order/admin/payment_type/byStoreId',
        loader: {
            get: async (data: any, config: any) => {
                const response: any = await services.api.get(
                    { ...data, onlineStoreId: getStoreInfo().id },
                    config
                );
                if (isEmpty(response)) return [];
                const result = response.map((item: { id: string; internalName: string }) => ({
                    name: item.internalName,
                    id: item.id,
                }));
                return result;
            },
        },
    },
];

/*
 * @Author: zhulu
 * @Date: 2022-09-23 10:54:16
 * @Description: 打印订单模板
 */

import React, { useMemo, useEffect } from 'react';
import { get } from 'lodash';
import JsBarcode from 'jsbarcode';
import { KV } from '@/interfaces';
import { services } from '@comall-backend-builder/core';
import { DELIVERY_TYPE_NAMES_TC, DELIVERY_TYPE_VALUES } from '@/constants';
import './order-receipt.less';
import classNames from 'classnames';
import { useGlobalConfig } from '@/utils/global-config';

interface IProps {
    data: KV;
    orderPrice: KV | undefined;
    /**
     * 打印打包单
     */
    isPackage?: boolean;
}

export function OrderReceipt(props: IProps) {
    const { data, orderPrice, isPackage } = props;
    const [{ storeLogos }] = useGlobalConfig();
    const storeLogo = useMemo(() => {
        const onlineStoreId = data.orderStore?.onlineStoreId || data.store?.id;
        return storeLogos.find((item) => item.onlineStoreId === onlineStoreId);
    }, [storeLogos]);

    const memberName = useMemo(() => {
        return `${data?.delivery?.orderLastName ?? ''}${data?.delivery?.orderFirstName ?? ''}`;
    }, [data]);

    const showAmount = (amount: string): boolean => {
        if (amount) {
            return amount.split(' ')[1] !== '0.00';
        } else {
            return false;
        }
    };

    const hasLack = (get(orderPrice, 'goods', []) || []).some(
        (goods: AnyObject) => goods.lackQuantity > 0
    );

    // 用于dom的id不能是纯存数字
    const id = 'order-' + data?.orderNumber;
    useEffect(() => {
        if (id) {
            JsBarcode(`#${id}`, data.orderNumber, {
                displayValue: false,
            });
        }
    }, [id]);

    return (
        <div className='order-receipt'>
            <header className='receipt-template-header'>
                <img src={storeLogo?.logo || './print-logo.png'} className='logo' alt='' />
                <div className='base-info'>
                    <img className='barcode' id={id}></img>
                    <div style={{ fontSize: '16px' }}>
                        {services.language.getText('components.OrderReceipt.receiptNumber')}：
                        {data?.orderNumber}
                    </div>
                    <div
                        style={{
                            fontWeight: 'normal',
                            fontSize: '12px',
                            marginTop: '6px',
                        }}
                    >
                        {data?.thirdPartyCreateTime ?? data?.createTime}
                    </div>
                    <div className='pay-method'>
                        {services.language.getText('components.OrderReceipt.paymentStatus')}：
                        {isPackage || data?.pay
                            ? services.language.getText('components.OrderReceipt.paid')
                            : services.language.getText('components.OrderReceipt.unpaid')}
                    </div>
                </div>
            </header>
            <main className='receipt-template-body'>
                <table className='order-info'>
                    <tr>
                        <th style={{ width: '23%' }}></th>
                        <th style={{ width: '27%' }}></th>
                        <th style={{ width: '23%' }}></th>
                        <th style={{ width: '27%' }}></th>
                    </tr>
                    <tr>
                        <td>{services.language.getText('components.OrderReceipt.subscriber')}：</td>
                        <td>{memberName}</td>
                        <td>
                            {services.language.getText('components.OrderReceipt.deliveryMethod')}：
                        </td>
                        <td>
                            {DELIVERY_TYPE_NAMES_TC[
                                data?.delivery?.deliveryType as DELIVERY_TYPE_VALUES
                            ] ?? '-'}
                        </td>
                    </tr>
                    <tr>
                        <td>{services.language.getText('components.OrderReceipt.orderEmail')}：</td>
                        <td>{data?.delivery?.orderEmail ?? data?.delivery?.email ?? '-'}</td>
                        <td>
                            {services.language.getText(
                                'components.OrderReceipt.expectedDeliveryTime'
                            )}
                            ：
                        </td>
                        <td>{data?.delivery?.preCanPickUpTime ?? '-'}</td>
                    </tr>
                    <tr>
                        <td>{services.language.getText('components.OrderReceipt.telNumber')}：</td>
                        <td>
                            {`${data?.delivery?.code ?? ''}${data.delivery?.orderMobile ?? ''}` ||
                                '-'}
                        </td>
                        <td>{services.language.getText('components.OrderReceipt.addressee')}：</td>
                        <td>
                            {`${data?.delivery?.pickUpLastName ?? ''}${data?.delivery
                                ?.pickUpFirstName ?? ''}`}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {services.language.getText('components.OrderReceipt.transportTo')}：
                        </td>
                        <td>
                            {data?.consignee?.address ?? data?.delivery?.deliveryAddress ?? '-'}
                        </td>
                        <td>
                            {services.language.getText('components.OrderReceipt.addressee')}
                            {services.language.getText('components.OrderReceipt.telNumber')}：
                        </td>
                        <td>{`${data?.delivery?.code ?? ''}${data.delivery?.pickUpMobile ??
                            ''}`}</td>
                    </tr>
                    <tr>
                        <td>
                            {services.language.getText('components.OrderReceipt.paymentMethod')}：
                        </td>
                        <td>{data?.paymentType ?? '-'}</td>
                        <td>{services.language.getText('components.OrderReceipt.remark')}：</td>
                        <td>{data?.orderRemark ?? '-'}</td>
                    </tr>
                </table>
            </main>
            <div className='receipt-template-table__order'>
                <table cellSpacing='0' cellPadding='10'>
                    <thead>
                        <tr>
                            <th style={{ width: '40%' }}>
                                {services.language.getText('components.OrderReceipt.goods')}
                            </th>
                            <th style={{ width: '10%' }}>
                                {services.language.getText('components.OrderReceipt.goodsNumber')}
                            </th>
                            <th style={{ width: '10%' }}>
                                {services.language.getText('components.OrderReceipt.number')}
                            </th>
                            <th style={{ width: '20%' }}>
                                {services.language.getText('components.OrderReceipt.price')}
                            </th>
                            <th style={{ width: '20%' }}>
                                {services.language.getText('components.OrderReceipt.subtotal')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(get(orderPrice, 'goods', []) || []).map((item: any) => (
                            <tr key={item.barCode}>
                                <td>{item.name || item.enName}</td>
                                <td>{item.csku}</td>
                                <td
                                    style={{
                                        wordBreak: 'keep-all',
                                    }}
                                >
                                    <div
                                        className={classNames({
                                            'lack-quantity': item.lackQuantity > 0,
                                        })}
                                    >
                                        {item.quantity}
                                    </div>
                                    {item.lackQuantity > 0 && (
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {item.pickingQuantity} (
                                            {item.pickingQuantity === 0
                                                ? services.language.getText(
                                                      'components.OrderReceipt.lack'
                                                  )
                                                : services.language.getText(
                                                      'components.OrderReceipt.partLack'
                                                  )}
                                            )
                                        </div>
                                    )}
                                </td>
                                <td
                                    style={{
                                        wordBreak: 'keep-all',
                                    }}
                                >
                                    {item.salePrices}
                                </td>
                                <td
                                    style={{
                                        wordBreak: 'keep-all',
                                    }}
                                >
                                    {item.amounts}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='receipt-template-footer'>
                <table cellSpacing='0'>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: '100px',
                                }}
                            ></th>
                            <th></th>
                        </tr>
                    </thead>
                    {orderPrice && (
                        <tbody>
                            <tr>
                                <td>
                                    {services.language.getText(
                                        'components.OrderReceipt.totalAmount'
                                    )}
                                </td>
                                <td>{orderPrice?.realTimeOrderAmountVo?.goodsAmount || '0.00'}</td>
                            </tr>
                            {showAmount(orderPrice?.realTimeOrderAmountVo?.freightFeeAmount) && (
                                <tr>
                                    <td>
                                        {services.language.getText(
                                            'components.OrderReceipt.freight'
                                        )}
                                    </td>
                                    <td>
                                        +
                                        {orderPrice?.realTimeOrderAmountVo?.freightFeeAmount ||
                                            '0.00'}
                                    </td>
                                </tr>
                            )}
                            {showAmount(orderPrice?.realTimeOrderAmountVo?.promotionAmount) && (
                                <tr>
                                    <td>
                                        {services.language.getText(
                                            'components.OrderReceipt.promotionDiscount'
                                        )}
                                    </td>
                                    <td>
                                        -
                                        {orderPrice?.realTimeOrderAmountVo?.promotionAmount ||
                                            '0.00'}
                                    </td>
                                </tr>
                            )}
                            {showAmount(orderPrice?.realTimeOrderAmountVo?.couponAmount) && (
                                <tr>
                                    <td>
                                        {services.language.getText(
                                            'components.OrderReceipt.discountCoupon'
                                        )}
                                    </td>
                                    <td>
                                        -{orderPrice?.realTimeOrderAmountVo?.couponAmount || '0.00'}
                                    </td>
                                </tr>
                            )}
                            {showAmount(
                                orderPrice?.realTimeOrderAmountVo?.freightFeeDiscountAmount
                            ) && (
                                <tr>
                                    <td>
                                        {services.language.getText(
                                            'components.OrderReceipt.freightDiscount'
                                        )}
                                    </td>
                                    <td>
                                        -
                                        {orderPrice?.realTimeOrderAmountVo
                                            ?.freightFeeDiscountAmount || '0.00'}
                                    </td>
                                </tr>
                            )}
                            {showAmount(orderPrice?.realTimeOrderAmountVo?.pointAmount) && (
                                <tr>
                                    <td>
                                        {services.language.getText(
                                            'components.OrderReceipt.integral'
                                        )}
                                    </td>
                                    <td>
                                        -{orderPrice?.realTimeOrderAmountVo?.pointAmount || '0.00'}
                                    </td>
                                </tr>
                            )}
                            {showAmount(orderPrice?.realTimeOrderAmountVo?.giftCardAmount) && (
                                <tr>
                                    <td>
                                        {services.language.getText(
                                            'components.OrderReceipt.redemptionNote'
                                        )}
                                    </td>
                                    <td>
                                        -
                                        {orderPrice?.realTimeOrderAmountVo?.giftCardAmount ||
                                            '0.00'}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td
                                    style={{
                                        borderTop: '1px solid #000',
                                    }}
                                >
                                    {services.language.getText(
                                        'components.OrderReceipt.paidPayTotalAmount'
                                    )}
                                </td>
                                <td
                                    style={{
                                        borderTop: '1px solid #000',
                                    }}
                                >
                                    {orderPrice?.realTimeOrderAmountVo?.paidPayAmount || '0.00'}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>
            {hasLack && (
                <div
                    style={{
                        marginTop: '20px',
                        textAlign: 'right',
                    }}
                >
                    {services.language.getText('components.OrderReceipt.lackTips')}
                </div>
            )}
            <footer className='footer'>
                <h4 className='footer-title'>
                    {services.language.getText('components.OrderReceipt.footerTitle')}
                </h4>
                {services.language
                    .getText('components.OrderReceipt.footerContent')
                    .split('\n\n')
                    .map((text) => (
                        <p key={text} className='footer-content'>
                            {text}
                        </p>
                    ))}
            </footer>
        </div>
    );
}

import React from 'react';

import { Form as AntForm } from 'antd';
import { OrderInfoProductProps } from '.';
import { language } from '@comall-backend-builder/core/lib/services';
import { GroupItems } from './group-items';

export default function GoodsForm(props: OrderInfoProductProps) {
    const { activeKey } = props;
    const { getFieldDecorator } = props.form;

    const { params } = props;

    return (
        <div style={{ display: activeKey === 1 ? 'block' : 'none' }}>
            <AntForm.Item
                label={language.getText('productAdd.basicMessage.selectProduct')}
                required
            >
                {getFieldDecorator(
                    'cskuGroups',
                    {}
                )(
                    <GroupItems
                        activeKey={activeKey}
                        onlineStoreIds={params?.onlineStoreIds}
                        {...props}
                    />
                )}
            </AntForm.Item>
        </div>
    );
}

import { Config } from '@comall-backend-builder/core/lib/parser';
import { DELIVERY_TYPE_OPTIONS } from '@/constants/order';
import { AFTER_SALE_OPTIONS, MEMBER_TYPE_OPTIONS } from '@/constants/after-sale';

export const config: Config = {
    entities: {
        afterSaleOrdersList: {
            apiPath: '/loader/dc-order/admin/aftermarket/find',
            filters: {
                receiveAddress: {
                    type: 'string',
                    displayName: '<<afterSale.filters.receiveAddress>>',
                    controlConfig: {
                        placeholder: '<<afterSale.filters.receiveAddressPlaceholder>>',
                    },
                },
                orderNumber: {
                    type: 'string',
                    displayName: '<<afterSale.filters.orderNumber>>',
                    controlConfig: {
                        placeholder: '<<defaultPlaceholderInput>>',
                    },
                },
                aftermarketNumber: {
                    type: 'string',
                    displayName: '<<afterSale.afterSaleNum>>',
                    controlConfig: {
                        placeholder: '<<defaultPlaceholderInput>>',
                    },
                },
                keyword: {
                    type: 'string.text.trim',
                    displayName: '<<keyword>>',
                    controlConfig: {
                        placeholder: '<<afterSale.filters.keyWordsPlaceHolder>>',
                    },
                },
                createTime: {
                    type: 'object.dateRange',
                    displayName: '<<afterSale.createTime>>',
                    controlConfig: {
                        allowClear: true,
                        style: { width: '100%' },
                    },
                },
                deliveryFlag: {
                    type: 'string.options.select',
                    displayName: '<<deliveryType.title>>',
                    options: DELIVERY_TYPE_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                    },
                },
                merchant: {
                    type: 'string.options.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant',
                    },
                    controlConfig: {
                        allowClear: true,
                    },
                },
                aftermarketStatus: {
                    type: 'string.options.select',
                    displayName: '<<afterSale.afterSaleOrderStatus>>',
                    options: AFTER_SALE_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                    },
                },
                memberType: {
                    type: 'string.options.select',
                    displayName: '<<afterSale.memberType>>',
                    options: MEMBER_TYPE_OPTIONS,
                    controlConfig: {
                        allowClear: true,
                    },
                },
                merchantId: {
                    type: 'string.options.select',
                    displayName: '<<productList.merchantName>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant/byUser',
                    },
                },
            },
        },
    },
    components: {
        AfterSaleOrdersContainer: {
            component: 'Viewport',
            entity: 'afterSaleOrdersList',
        },
        AfterSaleOrdersManagement: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'AfterSaleTabsFilter',
                    frequency: 30,
                },
            ],
        },
    },
};

import React, { useState, useRef } from 'react';
import { Form as AntForm, Button, Steps, Modal as AntModal, Spin } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services/language';
import AccessModal from '@/containers/offline-order/offline-create-basic-form/access-modal';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash-es';

import MemberForm from './member-form';
import GoodsForm from './goods-form';
import AddressForm from './address-form';
import CouponForm from './coupon-form';
import AmountConfirmModal from './amount-confirm-modal';
import './index.less';
import { useMemoizedFn } from 'ahooks';

export interface OrderInfoProductProps {
    [key: string]: any;
}

enum TabType {
    Member = 'member',
    Goods = 'goods',
    Address = 'address',
    Coupon = 'coupon',
}

const steps = [
    { title: language.getText('offlineOrder.create.selectMember'), type: TabType.Member },
    { title: language.getText('offlineOrder.create.selectGoods'), type: TabType.Goods },
    { title: language.getText('offlineOrder.create.selectAddress'), type: TabType.Address },
    { title: language.getText('offlineOrder.create.selectCoupon'), type: TabType.Coupon },
];
const { Step } = Steps;

/**
 * 创建订单（线下） - 表单
 */
const BaseOfflineCreateFormComponent = (props: OrderInfoProductProps) => {
    const [disabledCouponNo, setDisabledCouponNo] = useState(false);
    const [activeKey, setActiveKey] = useState(0);
    const { params } = props;
    const [memberInfo, setMemberInfo] = useState<AnyObject>({});
    const [touristsMemberId, setTouristsMemberId] = useState();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [cartData, setCartData] = useState<any>({});
    const [checkoutData, setCheckoutData] = useState<any>({});
    const [spinning, setSpinning] = useState(false);
    // 表单卸载后会丢失表单中的字段值，这里需缓存一份
    const cacheFormValues = useRef<AnyObject>({});
    const memberId = memberInfo?.memberId || touristsMemberId;
    const isVisitor = !memberInfo?.memberId;
    const hasOverrideCode = !!cartData?.cartBasket?.find((i: any) => !!i.overrideCode);

    const getCarts = async (data = {}) => {
        try {
            const cartData: any = await services.api.post(
                {
                    onlineStoreId: params?.onlineStoreIds,
                    memberId,
                    isVisitor,
                    memberSourceId: memberInfo?.memberSourceId,
                    ...data,
                },
                {
                    apiPath: `/dc-checkout-api/admin/offline/carts/api/getCartInfo`,
                }
            );
            setCartData(cartData);
            if (cartData?.cartBasket?.find((i: any) => !!i.overrideCode)) {
                props.form.setFieldsValue({ coupons: undefined });
            }
        } catch (error) {
            errorHandle(error as any);
        }
    };

    // 禁用CouponNo
    function handleRefreshDisabledCouponNo() {
        const { getFieldValue, setFieldsValue } = props.form;
        setTimeout(() => {
            if (getFieldValue('cskuGroups')?.some((item: any) => item.overrideCode)) {
                setFieldsValue({ couponNo: undefined });
                setDisabledCouponNo(true);
            } else {
                setDisabledCouponNo(false);
            }
        }, 0);
    }

    function renderItem(item: any) {
        switch (item.type) {
            case TabType.Member:
                return (
                    <MemberForm
                        {...props}
                        memberInfo={memberInfo}
                        touristsMemberId={touristsMemberId}
                        isVisitor={isVisitor}
                        activeKey={activeKey}
                        onLoadMemberInfo={setMemberInfo}
                    />
                );
            case TabType.Goods:
                return (
                    <GoodsForm
                        {...props}
                        memberInfo={memberInfo}
                        touristsMemberId={touristsMemberId}
                        isVisitor={isVisitor}
                        handleRefreshDisabledCouponNo={handleRefreshDisabledCouponNo}
                        activeKey={activeKey}
                        updateCart={getCarts}
                        cartData={cartData}
                    />
                );
            case TabType.Address:
                return (
                    <AddressForm
                        {...props}
                        memberInfo={memberInfo}
                        touristsMemberId={touristsMemberId}
                        isVisitor={isVisitor}
                        activeKey={activeKey}
                        checkoutData={checkoutData}
                    />
                );

            case TabType.Coupon:
                return (
                    <CouponForm
                        {...props}
                        memberInfo={memberInfo}
                        touristsMemberId={touristsMemberId}
                        hasOverrideCode={hasOverrideCode}
                        isVisitor={isVisitor}
                        cartData={cartData}
                        activeKey={activeKey}
                        disabledCouponNo={disabledCouponNo}
                        handleCheckout={handleCheckout}
                        checkoutData={checkoutData}
                    />
                );
        }
    }
    const handleCheckout = useMemoizedFn(async function(data: any = {}) {
        setSpinning(true);

        const formValues = (cacheFormValues.current = props.form.getFieldsValue());

        // 如果之前有优惠券，需要带上, 有overrideCode的商品就要禁用掉
        let checkoutParams: any = {
            singleCheckout: false,
            isVisitor,
            onlineStoreId: params?.onlineStoreIds,
            memberId,
            needDiff: true,
            offline: true,
            discountAmount: cartData?.summaryInfo?.discountAmount?.amount,
            payableAmount: cartData?.summaryInfo?.payableAmount?.amount,
            totalAmount: cartData?.summaryInfo?.totalAmount?.amount,
            remark: formValues?.remark,
            pointQuantity: formValues?.pointQuantity,
            selectedVoucherNums: checkoutData.selectedVoucherNums,
            pickStoreId: formValues?.pickStoreId,
            pickOnlineStoreId: formValues.pickOnlineStoreId,
            ...data,
        };

        // 选择收货方式前往选择优惠
        if (activeKey >= 2) {
            checkoutParams = {
                ...checkoutParams,
                deliveryType: formValues.deliveryType,
                memberAddress: {
                    childRegionId: formValues.area?.[1],
                    parentRegionId: formValues.area?.[0],
                    regionId: formValues.regionId,
                    firstName: formValues.user?.firstName,
                    lastName: formValues.user?.lastName,
                    gender: formValues.user?.gender,
                    mobile: formValues.mobile,
                    houseNumber: formValues.houseNumber,
                    streetAddress: formValues.streetAddress,
                },
                pickOnlineStoreId: formValues?.pickUpStoreId || checkoutData.pickOnlineStoreId,
                receiveDate:
                    formValues.deliveryType === 1
                        ? moment(formValues.deliveryDate).format('YYYY-MM-DD')
                        : moment(formValues.pickUpDate).format('YYYY-MM-DD'),
                checkoutParamsItemList: checkoutData?.checkoutMerchants?.map((i: any) => {
                    return {
                        merchantId: i.merchantId,
                        deliveryType: formValues?.deliveryType,
                        freightFeeAmount: get(formValues, `freightFeeAmount-${i.merchantId}`),
                    };
                }),
            };
        }

        // 选择优惠前往下单
        if (activeKey >= 3) {
            const couponList = cacheFormValues.current?.coupons || [];
            const couponIdList: any = couponList
                .filter((i: any) => i.type === 1)
                .map((i: any) => ({ id: i.id, type: i.idType }));
            const freightIdList: any = couponList
                .filter((i: any) => i.type === 2)
                .map((i: any) => ({ id: i.id, type: i.idType }));

            checkoutParams = {
                ...checkoutParams,
                couponIdList: data.couponIdList || couponIdList,
                freightIdList: data.freightIdList || freightIdList,
                pointQuantity:
                    cacheFormValues.current?.pointQuantity || checkoutData.pointQuantity || 0,
            };
        }

        try {
            const res: any = await services.api.post(checkoutParams, {
                apiPath: `/dc-checkout-api/admin/offline/checkout`,
            });
            setCheckoutData(res);
            setSpinning(false);
            props.form.setFieldsValue({ remark: res?.remark });
        } catch (error) {
            errorHandle(error as any);
            setSpinning(false);
            throw error;
        }
    });

    async function handleNext() {
        const successFn = () => {
            setActiveKey((value) => {
                let num = value + 1;
                num = num >= 3 ? 3 : num;
                return num;
            });
        };

        if (activeKey === 0) {
            let currentTouristsMemberId = touristsMemberId;
            if (!memberInfo?.memberId && !currentTouristsMemberId) {
                // 使用游客登录
                setNextLoading(true);
                try {
                    const res = (await services.api.get(
                        { onlineStoreId: props.params?.onlineStoreIds },
                        {
                            apiPath: `/dc-checkout-api/admin/offline/product/getTouristsMemberId`,
                        }
                    )) as any;
                    setTouristsMemberId((currentTouristsMemberId = res.id));
                    setNextLoading(false);
                } catch (error) {
                    errorHandle(error as any);
                    setNextLoading(false);
                    throw error;
                }
            }
            // 游客需要清理购物车
            if (!memberInfo?.memberId) {
                await services.api.delete(
                    {},
                    {
                        apiPath: `/dc-checkout-api/admin/offline/carts/api/clearCart/${currentTouristsMemberId}/${props.params.onlineStoreIds}`,
                    }
                );
            }
            setNextLoading(true);
            let data = { memberId: memberInfo?.memberId || touristsMemberId };
            await getCarts(data);
            setNextLoading(false);
            successFn();
        } else if (activeKey === 1) {
            props.form.validateFieldsAndScroll(['cskuGroups'], async (err: any) => {
                if (!err) {
                    setNextLoading(true);
                    handleCheckout({ needDiff: true })
                        .then(() => {
                            successFn();
                        })
                        .finally(() => {
                            setNextLoading(false);
                        });
                }
            });
        } else if (activeKey === 2) {
            props.form.validateFields((err: any, values: any) => {
                if (!err) {
                    setNextLoading(true);
                    handleCheckout()
                        .then(() => {
                            successFn();
                        })
                        .finally(() => {
                            setNextLoading(false);
                        });
                }
            });
        } else if (activeKey === 3) {
            props.form.validateFields(async (err: any, values: any) => {
                if (!err) {
                    setNextLoading(true);
                    try {
                        await handleCheckout();
                        handleSubmit();
                    } catch (error) {
                        setNextLoading(false);
                    }
                }
            });
        }
    }

    const createOffline = async () => {
        const couponList = cacheFormValues.current?.coupons || [];
        const couponIdList: any = couponList
            .filter((i: any) => i.type === 1)
            .map((i: any) => ({ id: i.id, type: i.idType }));
        const freightIdList: any = couponList
            .filter((i: any) => i.type === 2)
            .map((i: any) => ({ id: i.id, type: i.idType }));

        const params: any = {
            memberId,
            isVisitor,
            accessCode: props.params.accessCode,
            onlineStoreId: props.params?.onlineStoreIds,
            orderMemberInfo: checkoutData.orderMemberInfo,
            selectedVoucherNums: checkoutData.selectedVoucherNums,
            couponIdList: couponIdList || checkoutData.couponIdList,
            freightIdList: freightIdList || checkoutData.freightIdList,
            pointQuantity:
                cacheFormValues.current?.pointQuantity || checkoutData.pointQuantity || 0,
            remark: cacheFormValues.current?.remark || checkoutData.remark,
            deliveryType: cacheFormValues.current?.deliveryType || checkoutData.deliveryType,
            createOrderItemParamList: checkoutData?.checkoutMerchants?.map((i: any) => {
                return {
                    merchantId: i.merchantId,
                    deliveryType: cacheFormValues.current?.deliveryType,
                    freightFeeAmount: get(
                        cacheFormValues.current,
                        `freightFeeAmount-${i.merchantId}`
                    ),
                };
            }),
            deliveryDetail: {
                memberAddressVo: {
                    childRegionId: cacheFormValues.current.area?.[1],
                    parentRegionId: cacheFormValues.current.area?.[0],
                    firstName: cacheFormValues.current.user.firstName?.trim(),
                    lastName: cacheFormValues.current.user.lastName?.trim(),
                    gender: cacheFormValues.current.user.gender,
                    mobile: cacheFormValues.current.mobile,
                    regionId: cacheFormValues.current.regionId,
                    houseNumber: cacheFormValues.current.houseNumber?.trim(),
                    streetAddress: cacheFormValues.current.streetAddress?.trim(),
                },
                ...(checkoutData.checkoutMerchant?.deliveryTimes?.find(
                    (item: any) =>
                        item.date ===
                        moment(cacheFormValues.current.deliveryDate).format('YYYY-MM-DD')
                ) || {}),
            },
            consigneeInfo: {
                firstName: cacheFormValues.current.user.firstName,
                lastName: cacheFormValues.current.user.lastName,
                gender: cacheFormValues.current.user.gender,
                mobile: cacheFormValues.current.mobile,
                sameAsOrder: cacheFormValues.current?.consigneeMobile === memberInfo?.phone,
            },
            pickupDetail: {
                pickStoreId: cacheFormValues.current?.pickStoreId,
                pickOnlineStoreId: checkoutData.pickOnlineStoreId,
                ...(checkoutData.checkoutMerchant?.pickupTimes?.find(
                    (item: any) =>
                        item.date ===
                        moment(cacheFormValues.current.pickUpDate).format('YYYY-MM-DD')
                ) || {}),
            },
        };

        try {
            await services.api.post(params, {
                apiPath: `/dc-checkout-api/admin/offline/checkout/orders`,
            });
            setNextLoading(false);
            services.navigation.goto('/offlineOrders');
        } catch (error) {
            errorHandle(error as any);
            setNextLoading(false);
        }
    };

    async function handleSubmit() {
        await handleCheckout();
        const user = await services.api.get<AnyObject>(
            {},
            {
                apiPath: '/dc-user/admin/login_user',
            }
        );
        if (user.userJob?.key !== 1 && hasOverrideCode) {
            let accessCode = '';
            AntModal.confirm({
                icon: null,
                title: (
                    <div style={{ textAlign: 'center' }}>
                        {services.language.getText('offlineOrder.requiredAuthorization')}
                    </div>
                ),
                content: <AccessModal getValue={(value: string) => (accessCode = value)} />,
                onOk() {
                    params.accessCode = accessCode;
                    setShowConfirmModal(true);
                },
            });
        } else {
            setShowConfirmModal(true);
        }
    }

    return (
        <>
            <Spin spinning={spinning}>
                <Steps current={activeKey}>
                    {steps.map((item, index) => (
                        <Step key={index} title={item.title} />
                    ))}
                </Steps>
                <AntForm
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                    style={{ padding: '25px 0 25px' }}
                    className='offline-create-basic-form'
                >
                    {steps.map(renderItem)}
                </AntForm>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '15px',
                        margin: '15px',
                    }}
                >
                    {activeKey > 0 && (
                        <Button
                            onClick={() => {
                                setActiveKey((value) => {
                                    let num = value - 1;
                                    return num <= 0 ? 0 : num;
                                });
                            }}
                        >
                            {services.language.getText('salesPromotion.previousStep')}
                        </Button>
                    )}
                    {activeKey < steps.length - 1 && (
                        <Button
                            type='primary'
                            disabled={!cartData?.summaryInfo && activeKey === 1}
                            loading={nextLoading}
                            onClick={handleNext}
                        >
                            {services.language.getText('salesPromotion.nextStep')}
                        </Button>
                    )}
                    {activeKey === steps.length - 1 && (
                        <Button type='primary' onClick={handleSubmit}>
                            {services.language.getText('components.Button.submit')}
                        </Button>
                    )}
                </div>
                <AmountConfirmModal
                    checkoutData={checkoutData}
                    visible={showConfirmModal}
                    onOk={createOffline}
                    onCancel={() => {
                        setShowConfirmModal(false);
                    }}
                />
            </Spin>
        </>
    );
};
const offlineCreateFormInstance = AntForm.create()(BaseOfflineCreateFormComponent);

const mapStateToProps = (state: any, ownProps: any) => {
    const { entity } = ownProps;
    return { ...entity.fields };
};

export const OfflineCreateForm = connect(mapStateToProps, null)(offlineCreateFormInstance);

import { services } from '@comall-backend-builder/core';
import React, { useEffect, useState } from 'react';
import { find, get, isEmpty, isNumber } from 'lodash';
import {
    Input,
    InputNumber,
    Modal,
    Radio,
    Form as AntForm,
    Row as AntRow,
    Col as AntCol,
    Spin,
    Checkbox,
    Tag,
} from 'antd';
import { isEqual } from 'lodash';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import GoodSelector from './good-selector';
import GroupItemsDisplay from './group-items-display';
import { ErrorInfo } from '@comall-backend-builder/core/lib/services/error-handle';
import './index.less';
import { checkInvalidProduct } from '@/services/utils';
interface GoodParamsInterface {
    purchasesNum?: number;
    /** 0:自定义 1:预设 2:不使用 */
    codeType: 0 | 1 | 2;
    overrideCode?: string;
    overrideMoney?: number;
}
const goodParamsInit: GoodParamsInterface = {
    purchasesNum: undefined,
    codeType: 2,
    overrideCode: undefined,
    overrideMoney: undefined,
};

export const codeType: any = {
    0: language.getText('offlineOrder.create.customCode'),
    1: language.getText('offlineOrder.create.presetsCode'),
    2: language.getText('offlineOrder.create.noCode'),
};

export const SpecsType: any = {
    0: language.getText('offlineOrder.selectGoods.commonGood'),
    1: language.getText('offlineOrder.selectGoods.weightGood'),
};

export enum SELECTED {
    /**
     * 选中
     */
    YES = 1,
    /**
     * 未选中
     */
    NO = 0,
}

export const GroupItems = React.forwardRef((props: any) => {
    /** 弹出框信息 */
    const [modalInfo, setModalInfo] = useState<any>({});
    const [dataSource, setDataSource] = useState<any[]>([]);
    // 选择的赠品
    const [selectedMaps, setSelectedMaps] = useState<Record<string, AnyObject[]>>({});
    const memberId = props.memberInfo?.memberId || props.touristsMemberId;
    const [spinning, setSpinning] = useState(false);

    useEffect(() => {
        if (props.activeKey === 1 && !props.cartData) {
            props.updateCart();
        }
    }, [props.activeKey]);

    useEffect(() => {
        if (isEqual(props.value, dataSource)) {
            return;
        }
        let nDataSource: any[] = (props.value || []).map((item: any, index: number) => {
            let dataSourceItem = find(dataSource, (i) => i.id === item.id);
            let v = {
                ...dataSourceItem,
                ...item,
            };
            return v;
        });
        setDataSource([...nDataSource]);
        if (!isEqual(props.value, nDataSource) && props.value) {
            props.onChange(nDataSource, props.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    /** 弹出框表单信息 */
    const [goodParams, setGoodParams] = useState<GoodParamsInterface>(goodParamsInit);
    /** 弹出框显示 */
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    // 输入框状态与提示信息
    const [purchasesNumTips, setPurchasesNum] = useState<any>({
        status: null,
        help: null,
    });
    const [overrideCodeTips, setOverrideCode] = useState<any>({
        status: null,
        help: null,
    });
    const resetTips = () => {
        setPurchasesNum({ status: null, help: null });
        setOverrideCode({ status: null, help: null });
    };

    useEffect(() => {
        if (visible) {
            setGoodParams(goodParamsInit);
            resetTips();
        }
    }, [visible]);

    /** 弹出框确认 */
    const handleOk = () => {
        let pass = true;
        resetTips();
        //购买数量校验
        if (!goodParams.purchasesNum) {
            setPurchasesNum({
                status: 'error',
                help: language.getText('offlineOrder.tips.purchasesNum'),
            });
            pass = false;
            //库存校验
        } else if (!modalInfo?.stockOut && goodParams?.purchasesNum > modalInfo?.usableQuantity) {
            setPurchasesNum({
                status: 'error',
                help: language.getText('offlineOrder.tips.overInventory'),
            });
            pass = false;
        }
        if (!pass) return;

        //OverrideCode有效性校验
        if (goodParams.codeType !== 2 && goodParams.overrideCode) {
            /** 验证overrideCode */
            const overrideCodeVerify = () => {
                services.api
                    .get(
                        {
                            memberId,
                            isVisitor: props.isVisitor,
                            overrideCode: goodParams.overrideCode,
                            type: goodParams.codeType,
                            onlineStoreId: props.onlineStoreIds,
                            csku: modalInfo.simpleProductVo.csku,
                        },
                        {
                            apiPath: `/dc-price/admin/overridecode/middle/applyOverrideCode`,
                        }
                    )
                    .then((res: any) => {
                        let overrideData: any = {
                            overrideCode: goodParams.overrideCode,
                            overridePrice: goodParams.overrideMoney,
                            codeType: goodParams.codeType,
                        };
                        if (goodParams.codeType === 1)
                            overrideData.overridePrice = res?.priceReduction;
                        addCart(overrideData);
                    })
                    .catch((err) => {
                        const error = JSON.parse(JSON.stringify(err));
                        if (
                            error?.response?.body?.message === '4000001:OverrideCode错误或已过期!'
                        ) {
                            setOverrideCode({ status: 'error', help: 'OverrideCode错误或已过期' });
                        } else {
                            services.errorHandle(err);
                        }
                    });
            };
            overrideCodeVerify();
        } else {
            addCart();
        }
    };

    const addItemMulti = async (paramVolist: any) => {
        try {
            await services.api.post(
                {
                    memberId,
                    isVisitor: props.isVisitor,
                    onlineStoreId: props.onlineStoreIds,
                    paramVolist,
                },
                {
                    apiPath: `/dc-checkout-api/admin/offline/carts/api/itemMulti`,
                }
            );
        } catch (error) {
            errorHandle(error as ErrorInfo);
            throw error;
        }

        setSpinning(true);
        await props.updateCart();
        setSpinning(false);
    };

    const addCart = async (data: any = {}, isOrderGift?: boolean) => {
        let params;
        if (isOrderGift) {
            params = {
                onlineStoreId: props.onlineStoreIds,
                memberId,
                isVisitor: props.isVisitor,
                csku: data.csku,
                rsku: data.rsku,
                number: 1,
                goodsSpec: data.goodsSpec,
                goodsType: data.goodsType,
                orderPromotionId: data.orderPromotionId,
                isOrderGift: true,
            };
        } else {
            params = {
                ...data,
                isVisitor: props.isVisitor,
                onlineStoreId: props.onlineStoreIds,
                memberId,
                number: goodParams.purchasesNum,
                codeType: goodParams.codeType,
                pageType: 2,
                goodsSize: modalInfo.simpleProductVo.goodsSize,
                rsku: modalInfo.simpleProductVo.rsku,
                csku: modalInfo.simpleProductVo.csku,
                promotionId: modalInfo?.simpleProductVo?.promotionId,
                subGoods: Object.entries(selectedMaps).flatMap(([promotionId, values]) =>
                    values.map((value) => ({
                        promotionId,
                        number: goodParams.purchasesNum,
                        csku: value.csku,
                        rsku: value.rsku,
                        goodsSize: value.goodsSize,
                        goodsSpec: value.goodsSpec,
                    }))
                ),
            };
        }
        setSpinning(true);

        services.api
            .post(
                { ...params, isVisitor: props.isVisitor, memberId },
                {
                    apiPath: `/dc-checkout-api/admin/offline/carts/api/item`,
                }
            )
            .then(async () => {
                setVisible(false);
                props.updateCart().finally(() => {
                    setSpinning(false);
                });
            })
            .catch((error) => {
                errorHandle(error);
                setSpinning(false);
            });
    };
    const pickUpGood = (e: any, good: any) => {
        const checked = e.target.checked;
        const apiPath = checked
            ? `/dc-checkout-api/admin/offline/carts/api/${good.basketId}/${good.id}/${memberId}/${props.onlineStoreIds}/pickedUp`
            : `/dc-checkout-api/admin/offline/carts/api/${good.basketId}/${good.id}/${memberId}/${props.onlineStoreIds}/unPickedUp`;
        setSpinning(true);
        services.api
            .put(
                {
                    isVisitor: props.isVisitor,
                    memberId,
                },
                {
                    apiPath,
                }
            )
            .then(async () => {
                props.updateCart().finally(() => {
                    setSpinning(false);
                });
            })
            .catch((error) => {
                errorHandle(error);
                setSpinning(false);
            });
    };

    const removeGood = (goods: any, isGift: boolean) => {
        let params = {};
        if (isGift) {
            params = {
                isVisitor: props.isVisitor,
                itemId: goods.id,
                basketId: goods.basketId,
                goodsType: goods.goodsType,
                orderPromotionId: goods.orderPromotionId,
                isOrderGift: true,
                memberId,
            };
        }
        setSpinning(true);
        services.api
            .delete(
                { ...params, isVisitor: props.isVisitor, memberId },
                {
                    apiPath: `/dc-checkout-api/admin/offline/carts/api/deleteItem/${goods.basketId}/${goods.id}/${memberId}/${props.onlineStoreIds}`,
                }
            )
            .then(async () => {
                props.updateCart().finally(() => {
                    setSpinning(false);
                });
            })
            .catch((error) => {
                errorHandle(error);
                setSpinning(false);
            });
    };

    /** 弹出框取消 */
    const handleCancel = () => {
        setVisible(false);
        resetTips();
        setGoodParams(goodParamsInit);
    };

    /** 选择商品 */
    const selectGoodByCsku = async (csku: string) => {
        setVisible(false);
        setLoading(true);
        setModalInfo({});
        try {
            let data = await services.api.get(
                {
                    onlineStoreId: props.onlineStoreIds,
                    isVisitor: props.isVisitor,
                    memberId,
                },
                { apiPath: `/dc-checkout-api/admin/offline/product/${csku}/info` }
            );
            setModalInfo(data);
            setLoading(false);
            setVisible(true);
        } catch (error) {
            setLoading(false);
            services.errorHandle(error as ErrorInfo);
        }
    };

    /** 修改弹出框参数 */
    const changeGoodParams = (val: any, type: string) => {
        setGoodParams((x) => ({ ...x, [type]: val }));
    };

    const amount = props.cartData?.summaryInfo?.payableAmount?.amount;

    return (
        <Spin spinning={spinning}>
            {/* 选择框 */}
            <div className='select-goods-box'>
                <div>
                    <GoodSelector {...props} memberId={memberId} onSelect={selectGoodByCsku} />
                </div>
                {!!amount ? (
                    <span>
                        {language.getText('offlineOrder.create.totalPrice')}:{`  $${amount}`}
                    </span>
                ) : null}
            </div>
            {/* 产品列表 */}
            <GroupItemsDisplay
                {...props}
                removeGood={removeGood}
                pickUpGood={pickUpGood}
                addGood={addCart}
                addItemMulti={addItemMulti}
            />
            {/* 弹出层 */}
            <Modal
                title={language.getText('productAdd.basicMessage.selectProduct')}
                width={1000}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
            >
                <AntRow className='offline-create-goods-item'>
                    <AntCol span={4}>
                        <AntRow>
                            <img
                                className='product-img'
                                src={get(modalInfo, 'simpleProductVo.customPictures[0].url')}
                            />
                        </AntRow>
                    </AntCol>
                    <AntCol span={20}>
                        <AntCol span={24}>
                            <AntRow>
                                {language.getText('productList.productName')}：
                                {modalInfo?.simpleProductVo?.cskuName ||
                                    modalInfo?.simpleProductVo?.name ||
                                    '-'}
                            </AntRow>
                        </AntCol>
                        <AntCol span={24}>
                            <AntCol span={8}>
                                <AntRow>
                                    {language.getText('productList.csku')}:{' '}
                                    {modalInfo?.simpleProductVo?.csku ?? '-'}
                                </AntRow>
                                <AntRow>
                                    {language.getText('picking.price')}：
                                    <span>
                                        {modalInfo?.simplePriceVo?.price
                                            ? `$${modalInfo?.simplePriceVo?.price}`
                                            : '-'}
                                    </span>
                                </AntRow>
                                <AntRow>
                                    {language.getText('offlineOrder.create.stockOut')}：
                                    {modalInfo?.simpleProductVo?.stockOut
                                        ? language.getText('yes')
                                        : language.getText('no')}
                                </AntRow>
                            </AntCol>
                            <AntCol span={8}>
                                <AntRow>
                                    {language.getText('productList.barCode')}：
                                    {modalInfo?.simpleProductVo?.barcode ||
                                        modalInfo?.simpleProductVo?.barCode ||
                                        '-'}
                                </AntRow>
                                <AntRow>
                                    {language.getText('offlineOrder.create.directDiscount')}：
                                    {!!modalInfo?.simpleProductVo?.isSinglePrice
                                        ? language.getText('yes')
                                        : language.getText('no')}
                                </AntRow>
                                <AntRow>
                                    {language.getText('productList.merchantName')}：
                                    {modalInfo?.simpleProductVo?.merchantName ?? '-'}
                                </AntRow>
                            </AntCol>
                            <AntCol span={8}>
                                <AntRow>
                                    {language.getText('productList.specsType')}：
                                    {isNumber(modalInfo?.simpleProductVo?.specsType)
                                        ? SpecsType[modalInfo?.simpleProductVo?.specsType]
                                        : '-'}
                                </AntRow>
                                <AntRow>
                                    {language.getText('offlineOrder.create.memberPrice')}：
                                    {!!modalInfo?.simpleProductVo?.isMemberPrice
                                        ? language.getText('yes')
                                        : language.getText('no')}
                                </AntRow>
                                <AntRow>
                                    {language.getText('offlineOrder.create.ableSaleNum')}：
                                    {modalInfo?.simpleProductVo?.availableStock}
                                </AntRow>
                            </AntCol>
                        </AntCol>
                    </AntCol>
                </AntRow>
                <AntForm labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}>
                    <AntForm.Item
                        label={
                            <>
                                <span style={{ color: 'red' }}>* </span>
                                {language.getText('offlineOrder.purchasesNum')}
                            </>
                        }
                        validateStatus={purchasesNumTips.status}
                        help={purchasesNumTips.help}
                    >
                        <InputNumber
                            id='purchasesNum'
                            placeholder={language.getText('defaultPlaceholderInput')}
                            min={1}
                            max={99999999}
                            precision={0}
                            value={goodParams.purchasesNum}
                            onChange={(e) => changeGoodParams(e, 'purchasesNum')}
                        />
                    </AntForm.Item>
                    <AntForm.Item
                        label={
                            <>
                                <span style={{ color: 'red' }}>* </span>
                                {language.getText('offlineOrder.create.codeType')}
                            </>
                        }
                    >
                        <Radio.Group
                            value={goodParams.codeType}
                            onChange={(e) => changeGoodParams(e.target.value, 'codeType')}
                        >
                            <Radio value={2}>{codeType[2]}</Radio>
                            <Radio value={1}>{codeType[1]}</Radio>
                            <Radio value={0}>{codeType[0]}</Radio>
                        </Radio.Group>
                    </AntForm.Item>
                    {goodParams.codeType !== 2 ? (
                        <AntForm.Item
                            label={'OverrideCode'}
                            validateStatus={overrideCodeTips.status}
                            help={overrideCodeTips.help}
                        >
                            <Input
                                id='overrideCode'
                                placeholder={language.getText('defaultPlaceholderInput')}
                                maxLength={20}
                                value={goodParams.overrideCode}
                                onChange={(e) => changeGoodParams(e.target.value, 'overrideCode')}
                            />
                        </AntForm.Item>
                    ) : null}
                    {goodParams.codeType === 0 ? (
                        <AntForm.Item label={language.getText('offlineOrder.create.overrideMoney')}>
                            <InputNumber
                                id='overrideMoney'
                                placeholder={services.language.getText('defaultPlaceholderInput')}
                                type='number'
                                min={0}
                                max={99999999}
                                precision={2}
                                onChange={(e) => changeGoodParams(e, 'overrideMoney')}
                            />
                        </AntForm.Item>
                    ) : null}
                    <GiftSelector
                        productGiftList={modalInfo?.productGiftList || []}
                        value={selectedMaps}
                        onChange={setSelectedMaps}
                    />
                </AntForm>
            </Modal>
        </Spin>
    );
});

const GiftSelector: React.FC<{
    productGiftList: AnyObject[];
    value: Record<string, AnyObject[]>;
    onChange(value: Record<string, AnyObject[]>): void;
}> = ({ productGiftList, value, onChange }) => {
    // 初始化换购中选中的商品
    useEffect(() => {
        const selectedMaps: Record<string, AnyObject[]> = {};
        for (const productGift of productGiftList) {
            const selectedValues: AnyObject[] = (selectedMaps[productGift.promotionId] = []);
            // 过滤售罄商品后的 items
            const items = (productGift.items || []).filter(
                (item: AnyObject) => !checkInvalidProduct(item)
            );
            for (const item of items) {
                if (item.swapSelect === SELECTED.YES) {
                    selectedValues.push(item);

                    // 单选框类型，找到默认选中后，不需要继续查找
                    if (productGift.swap === 1) {
                        break;
                    }
                }
            }
            // 单选框类型，不存在默认选中时，把第一个设为默认选中
            if (productGift.swap === 1 && selectedValues.length === 0 && items.length) {
                selectedValues.push(items[0]);
            }
        }
        onChange(selectedMaps);
    }, [productGiftList]);

    /**
     * 单选选中
     */
    function selectRadio(promotionId: string, item: AnyObject) {
        if (value[promotionId][0] !== item) {
            onChange({
                ...value,
                [promotionId]: [item],
            });
        }
    }

    /**
     * 多选选中
     */
    function selectCheckbox(promotionId: string, item: AnyObject) {
        const selectedValues = value[promotionId];
        if (selectedValues.includes(item)) {
            onChange({ ...value, [promotionId]: selectedValues.filter((value) => value !== item) });
        } else {
            onChange({ ...value, [promotionId]: [...selectedValues, item] });
        }
    }

    if (isEmpty(productGiftList)) return null;

    return (
        <>
            {productGiftList.map((productGift: AnyObject) => {
                return (
                    !!productGift?.items?.length && (
                        <div key={productGift.promotionId}>
                            <h3 style={{ marginBottom: 20 }}>{productGift.changeTitle}</h3>
                            {productGift.items.map((giftItem: AnyObject) => {
                                const selectedValues = value[productGift.promotionId] ?? [];
                                const currentSelected =
                                    productGift.swap === 1
                                        ? selectedValues[0] === giftItem
                                        : selectedValues.includes(giftItem);

                                return (
                                    <ProductGiftItem
                                        giftItem={giftItem}
                                        showImg
                                        renderHandler={() => (
                                            <div className='product-gift-item-select-wrapper'>
                                                {productGift.swap === 1 ? (
                                                    <Radio
                                                        checked={currentSelected}
                                                        disabled={checkInvalidProduct(giftItem)}
                                                        onChange={() =>
                                                            selectRadio(
                                                                productGift.promotionId,
                                                                giftItem
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <Checkbox
                                                        checked={currentSelected}
                                                        disabled={checkInvalidProduct(giftItem)}
                                                        onChange={() =>
                                                            selectCheckbox(
                                                                productGift.promotionId,
                                                                giftItem
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        )}
                                    />
                                );
                            })}
                        </div>
                    )
                );
            })}
        </>
    );
};

export const ProductGiftItem: React.FC<{
    giftItem: AnyObject;
    tag?: string;
    showImg?: boolean;
    renderHandler(): React.ReactNode;
}> = ({ giftItem, tag, showImg, renderHandler }) => {
    const price = giftItem.priceDutyFree || giftItem.salePrice;

    return (
        <AntRow className='offline-create-goods-item' key={giftItem.csku}>
            <AntCol span={4}>
                <AntRow>
                    {showImg && (
                        <img className='product-img' src={giftItem.pictureUrl || giftItem.pic} />
                    )}
                </AntRow>
            </AntCol>
            <AntCol span={16}>
                <AntCol span={24}>
                    <AntRow>
                        {language.getText('productList.productName')}：
                        {giftItem.cskuName || giftItem.name || '-'}
                        {tag && (
                            <Tag style={{ marginLeft: '10px' }} color='green'>
                                {tag}
                            </Tag>
                        )}
                    </AntRow>
                </AntCol>
                <AntCol span={24}>
                    <AntCol span={8}>
                        <AntRow>
                            {language.getText('productList.csku')}：{giftItem.csku || '-'}
                        </AntRow>
                        <AntRow>
                            {language.getText('offlineOrder.create.exchangePrice')}：
                            {price ? `$${price}` : '-'}
                        </AntRow>
                    </AntCol>
                    <AntCol span={8}>
                        <AntRow>
                            {language.getText('productList.barCode')}：
                            {giftItem.barCode || giftItem.barcode || '-'}
                        </AntRow>
                    </AntCol>
                    <AntCol span={8}>
                        <AntRow>
                            {language.getText('productList.specsType')}：
                            {isNumber(giftItem?.specsType) ? SpecsType[giftItem?.specsType] : '-'}
                        </AntRow>
                    </AntCol>
                </AntCol>
            </AntCol>
            <AntCol span={4}>{renderHandler()}</AntCol>
        </AntRow>
    );
};

import { EntityProperties } from '@comall-backend-builder/core/lib/parser';
import {
    DELIVERY_TYPE_VALUES,
    ORDER_STATUS_ALL_OPTIONS,
    ORDER_STATUS_ALL_VALUES,
} from '@/constants/order';
import {
    DELIVERY_TYPE_OPTIONS,
    LIQUID_STATE_OPTIONS,
    LIQUID_STATE_VALUES,
    REPORT_SALE_CHANNEL_OPTIONS,
} from '@/constants/report';
import { REFUND_OPTIONS, REFUND_STATUS_VALUES } from '@/constants';

/**
 * 通过properties获取form的fields
 * @param config
 * @returns
 */
export const getFields = (config: EntityProperties) => {
    let nVal: AnyObject[] = [];

    Object.keys(config).forEach((item) => {
        nVal.push({ property: item });
    });

    return nVal;
};

/**
 * 报表筛选项 --所有的
 */
export const allFilterFields = {
    onlineStoreIds: {
        type: 'array.treeNodeIds.tree',
        displayName: '<<report.storeId>>',
        options: [],
        source: {
            apiPath: '/loader/dc-store/admin/onlineStore/own',
        },
        controlConfig: {
            allowClear: true,
            treeCheckable: true,
            treeNodeFilterProp: 'name',
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    orderTime: {
        type: 'object.dateTimeRange',
        displayName: '<<report.orderTime>>',
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
            showTime: { defaultValue: ['00:00:00', '23:59:59'] },
        },
    },
    shipTime: {
        type: 'object.dateTimeRange',
        displayName: '<<report.shipTime>>',
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
            showTime: { defaultValue: ['00:00:00', '23:59:59'] },
        },
    },
    deliveryTime: {
        type: 'object.dateTimeRange',
        displayName: '<<report.deliveryTime>>',
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
            showTime: { defaultValue: ['00:00:00', '23:59:59'] },
        },
    },
    orderDeliveryType: {
        type: 'string.options.select',
        displayName: '<<report.deliveryType.title>>',
        options: DELIVERY_TYPE_OPTIONS,
        defaultValue: DELIVERY_TYPE_VALUES.DELIVERY,
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
        },
    },
    orderStatus: {
        type: 'array.optionIds.select',
        displayName: '<<report.orderStatus>>',
        options: ORDER_STATUS_ALL_OPTIONS,
        defaultValue: [ORDER_STATUS_ALL_VALUES.DELIVERING],
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
    pickUpMember: {
        type: 'string',
        displayName: '<<report.contactPerson>>',
        controlConfig: {
            allowClear: true,
            placeholder: '<<report.contactPersonPlaceholder>>',
        },
    },
    orderNumber: {
        type: 'string',
        displayName: '<<report.orderNumber>>',
    },
    orderMember: {
        type: 'string',
        displayName: '<<report.orderPerson>>',
        controlConfig: {
            allowClear: true,
            placeholder: '<<report.orderPersonPlaceholder>>',
        },
    },
    refundTime: {
        type: 'object.dateTimeRange',
        displayName: '<<report.refundTime>>',
        controlConfig: {
            allowClear: true,
            showArrow: true,
            style: { width: '100%' },
            showTime: { defaultValue: ['00:00:00', '23:59:59'] },
        },
    },
    refundStatus: {
        type: 'string.options.select',
        displayName: '<<report.refundStatus>>',
        options: REFUND_OPTIONS.filter((i) => i.id !== REFUND_STATUS_VALUES.ALL),
        controlConfig: {
            allowClear: true,
            showArrow: true,
        },
    },
    liquidState: {
        type: 'string.options.select',
        displayName: '<<report.liquidState>>',
        options: LIQUID_STATE_OPTIONS,
        defaultValue: LIQUID_STATE_VALUES.OTHER,
        controlConfig: {
            showArrow: true,
            style: { width: '100%' },
        },
    },
    merchantIds: {
        type: 'array.optionIds.select',
        displayName: '<<report.merchant>>',
        options: [],
        source: {
            apiPath: '/loader/dc-goods/admin/merchant/byUser',
        },
        controlConfig: {
            allowClear: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
    isOfflineOrder: {
        type: 'string.options.select',
        displayName: '<<report.isOfflineOrder>>',
        options: REPORT_SALE_CHANNEL_OPTIONS,
        controlConfig: {
            allowClear: true,
            placeholder: '<<defaultPlaceholderSelect>>',
        },
    },
};

type filterFields = keyof typeof allFilterFields;

/**
 * 获取筛选properties
 * @param filterKey
 * @returns
 */
export const getFilterProperties = (filterKey: string[] = [], hasDefault?: boolean) => {
    let nVal: AnyObject = {};
    hasDefault = hasDefault || false;
    filterKey.forEach((i) => {
        nVal[i] = JSON.parse(JSON.stringify(allFilterFields[i as filterFields]));
        if (i !== 'liquidState') {
            if (!hasDefault) delete nVal[i]?.defaultValue;
        }
    });
    return nVal;
};

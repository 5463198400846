import { FORM_EXCHANGE_VALUES } from './../../../constants/sales-multi-promotion';
import {
    PROMOTION_TYPE_VALUES,
    STYLE_PRESENTATION_VALUES,
    TYPE_CONDITION_VALUES,
    STYLE_PRESENTATION_OPTIONS,
} from '@/constants';
import { buildFormFieldsByLanguage } from '@/services/language-utils';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
import { getCheckValue, validateConditionEmpty, validateConditionRange } from './service';

export const dictItems = {
    1: [
        {
            renderType: 'field',
            property: 'showType',
            key: 'stylePresentation',
            formItem: {
                labelCol: { span: 2 },
                wrapperCol: { span: 10 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: false,
                    },
                ],
                initialValue: STYLE_PRESENTATION_VALUES.GENERA,
            },
        },
        {
            renderType: 'field',
            property: 'discRule',
            key: 'typeDiscount',
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
    ],
    2: [
        {
            renderType: 'field',
            property: 'showType',
            key: 'stylePresentation',
            formItem: {
                labelCol: { span: 2 },
                wrapperCol: { span: 10 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: false,
                    },
                ],
                initialValue: STYLE_PRESENTATION_VALUES.GENERA,
            },
        },
        {
            renderType: 'field',
            property: 'discValue',
            key: 'singleItemStraightDown',
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
    ],
    3: [
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            fieldOpts: {
                rules: [
                    {
                        whitespace: true,
                        required: true,
                    },
                ],
            },
        }),
        {
            renderType: 'field',
            property: 'fullReduction',
            key: 'fullReduction',
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                    {
                        validator: (_rule: any, value: any, callback: any) => {
                            const checkValue = getCheckValue(value, [
                                'conditionValue',
                                'prefrValue',
                            ]);
                            if (validateConditionEmpty(checkValue)) {
                                return new Error(
                                    services.language.getText(
                                        'salesPromotion.conditionsReward.empty'
                                    )
                                );
                            }
                            validateConditionRange(checkValue, callback);
                        },
                    },
                ],
            },
        },
    ],
    4: [
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        {
            renderType: 'field',
            property: 'numberFullPieces',
            key: 'numberFullPieces',
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                    {
                        validator: (_rule: any, _value: any, callback: any) => {
                            const checkValue = getCheckValue(_value, [
                                'conditionValue',
                                'prefrValue',
                            ]);
                            if (validateConditionEmpty(checkValue)) {
                                return new Error(
                                    services.language.getText(
                                        'salesPromotion.conditionsReward.empty'
                                    )
                                );
                            }
                            validateConditionRange(checkValue, callback);
                        },
                    },
                ],
            },
        },
    ],
    5: [
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        {
            renderType: 'field',
            property: 'fullFoldFullAmount',
            key: 'fullFoldFullAmount',
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                    {
                        validator: (_rule: any, _value: any, callback: any) => {
                            const checkValue = getCheckValue(_value, [
                                'conditionValue',
                                'prefrValue',
                                'reliefCeiling',
                            ]);
                            if (validateConditionEmpty(checkValue)) {
                                return new Error(
                                    services.language.getText(
                                        'salesPromotion.conditionsReward.empty'
                                    )
                                );
                            }
                            validateConditionRange(checkValue, callback);
                        },
                    },
                ],
            },
        },
    ],
    6: [
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        {
            renderType: 'field',
            property: 'fullSubtractionFullPieces',
            key: 'fullSubtractionFullPieces',
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                    {
                        validator: (_rule: any, _value: any, callback: any) => {
                            const checkValue = getCheckValue(_value, [
                                'conditionValue',
                                'prefrValue',
                                'reliefCeiling',
                            ]);
                            if (validateConditionEmpty(checkValue)) {
                                return new Error(
                                    services.language.getText(
                                        'salesPromotion.conditionsReward.empty'
                                    )
                                );
                            }
                            validateConditionRange(checkValue, callback);
                        },
                    },
                ],
            },
        },
    ],
    7: [
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        {
            renderType: 'field',
            property: 'amountFull',
            key: 'amountFull',
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                    {
                        validator: (_rule: any, _value: any, callback: any) => {
                            const checkValue = getCheckValue(_value, [
                                'conditionValue',
                                'prefrValue',
                                'reliefCeiling',
                            ]);
                            if (validateConditionEmpty(checkValue)) {
                                return new Error(
                                    services.language.getText(
                                        'salesPromotion.conditionsReward.empty'
                                    )
                                );
                            }
                            validateConditionRange(checkValue, callback);
                        },
                    },
                ],
            },
        },
    ],
    8: [
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        {
            renderType: 'field',
            property: 'fullNumber',
            key: 'fullNumber',
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                    {
                        validator: (_rule: any, _value: any, callback: any) => {
                            const checkValue = getCheckValue(_value, [
                                'conditionValue',
                                'prefrValue',
                                'reliefCeiling',
                            ]);
                            if (validateConditionEmpty(checkValue)) {
                                return new Error(
                                    services.language.getText(
                                        'salesPromotion.conditionsReward.empty'
                                    )
                                );
                            }
                            validateConditionRange(checkValue, callback);
                        },
                    },
                ],
            },
        },
    ],
    9: [
        {
            renderType: 'field',
            property: 'typeRewardGiveaways',
            key: 'giveGift',
            disabled: true,
            fieldOpts: {
                initialValue: services.language.getText('salesPromotion.conditionsReward.giveGift'),
                rules: [
                    {
                        required: false,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'freeGiftThresholdPrice',
            key: 'freeGiftThresholdPrice',
            disabled: false,
            min: 0.01,
            step: 0.01,
            max: 99999999.99,
            renderAble: (values: AnyObject) => values.conditionType === TYPE_CONDITION_VALUES.MONEY,
            formItem: {
                labelCol: { span: 8 },
                wrapperCol: { span: 12 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'freeGiftThresholdNums',
            key: 'freeGiftThresholdNums',
            max: 99999,
            step: 1,
            min: 1,
            renderAble: (values: AnyObject) => values.conditionType === TYPE_CONDITION_VALUES.COUNT,
            disabled: false,
            formItem: {
                labelCol: { span: 8 },
                wrapperCol: { span: 12 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'titleGift',
            maxLength: 50,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        {
            renderType: 'field',
            property: 'chooseProduct',
            key: 'chooseProduct',
            disabled: false,
            formItem: {
                labelCol: { span: 5 },
                wrapperCol: { span: 12 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                    {
                        validator: (_rule: any, value: any, callback: any) => {
                            if (value.some((item: any) => isEmpty(item.id)))
                                return new Error(
                                    services.language.getText('productShelf.pleaseSelectProducts')
                                );

                            callback();
                        },
                    },
                ],
            },
        },
    ],
    10: [
        {
            renderType: 'field',
            property: 'typeRewardGiveaways',
            key: 'tradeUpPrice',
            disabled: true,
            fieldOpts: {
                initialValue: services.language.getText(
                    'salesPromotion.conditionsReward.tradeUpPrice'
                ),
                rules: [
                    {
                        required: false,
                    },
                ],
            },
        },
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            renderAble: (values: AnyObject) => values.type !== PROMOTION_TYPE_VALUES.SINGLE,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        {
            renderType: 'field',
            property: 'freeGiftThresholdPrice',
            key: 'freeGiftThresholdPrice',
            disabled: false,
            renderAble: (values: AnyObject) => values.conditionType === TYPE_CONDITION_VALUES.MONEY,
            formItem: {
                labelCol: { span: 8 },
                wrapperCol: { span: 12 },
            },
            min: 0.01,
            step: 0.01,
            max: 99999999.99,
            formatField: (values: AnyObject) => {
                return {
                    displayName: services.language.getText(
                        'salesPromotion.conditionsReward.changeGiftThresholdPrice'
                    ),
                };
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'freeGiftThresholdNums',
            key: 'freeGiftThresholdNums',
            max: 99999,
            step: 1,
            min: 1,
            renderAble: (values: AnyObject) => values.conditionType === TYPE_CONDITION_VALUES.COUNT,
            disabled: false,
            formItem: {
                labelCol: { span: 8 },
                wrapperCol: { span: 12 },
            },
            formatField: (values: AnyObject) => {
                return {
                    displayName: services.language.getText(
                        'salesPromotion.conditionsReward.changeGiftThresholdPrice'
                    ),
                };
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'swap',
            key: 'swap',
            disabled: false,
            fieldOpts: {
                rules: [
                    {
                        required: false,
                    },
                ],
                initialValue: FORM_EXCHANGE_VALUES.RADIO,
            },
        },
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'changeTitle',
            maxLength: 50,
            fieldOpts: {
                rules: [{ whitespace: true, required: true }],
            },
        }),
        {
            renderType: 'field',
            property: 'showType',
            key: 'showType',
            disabled: false,
            options: STYLE_PRESENTATION_OPTIONS.filter(
                (i: any) => i.id !== STYLE_PRESENTATION_VALUES.HIGHLIGHTED_IN_LIST
            ),
            fieldOpts: {
                rules: [
                    {
                        required: false,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'exchangeProduct',
            key: 'exchangeProduct',
            disabled: false,
            formItem: {
                wrapperCol: { span: 24 },
            },
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                    {
                        validator: (_rule: any, value: any, callback: any) => {
                            if (
                                value.some(
                                    (item: any) =>
                                        isEmpty(item.product) || item.exchangePurchasePrice < 0
                                )
                            )
                                return new Error(
                                    services.language.getText('productShelf.pleaseSelectProducts')
                                );

                            callback();
                        },
                    },
                ],
            },
        },
    ],
    11: [
        {
            renderType: 'field',
            property: 'typeRewardGiveaways',
            key: 'typeRewardGiveaways',
            disabled: true,
            fieldOpts: {
                initialValue: services.language.getText(
                    'salesPromotion.conditionsReward.threePieces'
                ),
                rules: [
                    {
                        required: false,
                    },
                ],
            },
        },
        ...buildFormFieldsByLanguage({
            renderType: 'field',
            property: 'promotionLabel',
            maxLength: 100,
            fieldOpts: {
                rules: [
                    {
                        whitespace: true,
                        required: true,
                    },
                ],
            },
        }),
        {
            renderType: 'field',
            property: 'threeTotalPrice',
            key: 'threeTotalPrice',
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'merchandiseGroup',
            key: 'merchandiseGroup',
            fieldOpts: {
                rules: [
                    {
                        required: false,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'applicableCommodityType',
            key: 'applicableCommodityType',
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'applicableBrand',
            key: 'applicableBrand',
            fieldOpts: {
                rules: [
                    {
                        required: true,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'excludingTypeGoods',
            key: 'excludingTypeGoods',
            fieldOpts: {
                rules: [
                    {
                        required: false,
                    },
                ],
            },
        },
        {
            renderType: 'field',
            property: 'listGoods',
            key: 'listGoods',
            fieldOpts: {
                rules: [
                    {
                        required: false,
                    },
                ],
            },
        },
    ],
};

import React, { useRef, useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { each, isArray, isObject, map, get, isEmpty } from 'lodash';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { services } from '@comall-backend-builder/core';
// @ts-ignore
import loadingUrl from './loading.gif';
import { Modal, Tooltip as AntTooltip } from 'antd';
import { VideoBlot, ImageBlot } from './format';
import { UploadImageVideo } from '../upload-image-video';
import './index.less';
import { useMount, useUpdateEffect } from 'ahooks';

export interface EditorModulesProps {
    /**
     * 工具栏id
     */
    toolbarId: string;
}

export interface EditorProps {
    /**
     * 当前值
     */
    value: string;

    className?: string;

    style?: React.CSSProperties;

    img?: {
        imgPlaceholder?: string;
        fileName: string;
        uploadUrl: string;
        accept?: string;
        maxSize?: number; //上传文件的最大文件大小限制，单位kb
        maxCount?: number; //图片最大可上传数   需小于总最大可上传数
    };
    video?: {
        fileName: string;
        uploadUrl: string; //上传地址
        accept?: string; //
        maxSize?: number; //上传文件的最大文件大小限制，单位kb
        maxCount?: number; //视频最大可上传数   需小于总最大可上传数
        maxDuration?: number; //时长,单位 s
    };

    /**
     * 占位提示
     */
    placeholder?: string;
    /**
     * 是否禁用
     */
    disabled?: boolean;
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: string, name: string) => void;
    /**
     * 富文本失焦
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onBlur: (value: string, name: string) => void;
    changeValue: (value: string, name: string) => void;
}

declare var window: Window & { Quill: any };

const SIZE_LIST = [
    '10px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
    '26px',
    '28px',
    '30px',
    '32px',
    '34px',
    '36px',
];

const richTextFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    // 'code-block',
    // 'list',
    'bullet',
    'script',
    'indent',
    'direction',
    'size',
    'color',
    // 'background',
    'font',
    'align',
    // 'link',
    'image',
    'video',
];
const modules = [
    // [{ header: [1, 2, 3, 4, 5, 6, 0] }],
    // ['bold', 'italic', 'underline', 'strike'],
    // [{ indent: '-1' }, { indent: '+1' }],
    // [{ direction: 'rtl' }],
    [{ size: SIZE_LIST }],
    [{ color: [] }],
    ['image'],
    ['video'],
    // ['clean'],
];

//quill的调整图片大小模块需要全局的quill变量
window.Quill = Quill;

const ImageResize = require('quill-image-resize-module');
Quill.register('modules/ImageResize', ImageResize, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = SIZE_LIST;
Quill.register(Size, true);

Quill.register(VideoBlot, true);
Quill.register(ImageBlot, true);

function getObjectFirstKey(object: any) {
    return Reflect.ownKeys(object)[0];
}

let id = 0;
function generateToolbarId() {
    const toolbarId = 'toolbar-' + id;
    id++;
    return toolbarId;
}

const EditorModules: React.FC<EditorModulesProps> = (props) => {
    /**
     * 渲染具有下拉选项的toolbar
     * @param {String} keyName quill中的toolbar名称，需要渲染成ql-{keyName}的格式
     * @param {Array} value
     * @return {ReactElement}
     */
    const renderSelectableBar = (keyName: any, value: any) => {
        return (
            <span>
                <select className={`ql-${keyName}`}>
                    {map(value, (option, optionIndex) => (
                        <option key={optionIndex} value={option} />
                    ))}
                </select>
            </span>
        );
    };

    /**
     * 渲染format中的每一个toolbar以及其对应的tooltip
     * @param {Object|String} format
     * @param {Number} toolIndex
     * @return {ReactElement}
     */
    const renderToolBarWithTip = (format: any, toolIndex: any) => {
        let renderedComponent;
        let keyName = '';
        let tipKey = '';
        if (!isObject(format)) {
            keyName = format;
            tipKey = format;
            //最简单的字符串，渲染成指定class的button
            renderedComponent = <button className={`ql-${keyName}`} />;
        } else {
            keyName = getObjectFirstKey(format) as string;
            //@ts-ignore
            const value = format[keyName];
            if (isArray(value)) {
                tipKey = keyName;
                //对象值为数组时，渲染成指定class的下拉bar
                renderedComponent = renderSelectableBar(keyName, value);
            } else {
                tipKey = `${keyName}${value}`;
                //对象值为非数组时，渲染成指定class并带有特定值的button
                renderedComponent = <button className={`ql-${keyName}`} value={value} />;
            }
        }
        const TOOLTIPS: any = services.language.getText('components.RichText.tooltips');
        return (
            <AntTooltip key={toolIndex} title={TOOLTIPS[tipKey]}>
                {renderedComponent}
            </AntTooltip>
        );
    };

    /**
     * 根据modules中的每一项渲染一组format，
     * @return {Array<ReactElement>}
     * @param {Array} formats
     */
    const renderFormats = (formats: any) => {
        return map(formats, (format, toolIndex) => renderToolBarWithTip(format, toolIndex));
    };

    const { toolbarId } = props;
    return (
        <div id={toolbarId}>
            {map(modules, (formats, formatIndex) => (
                <span key={formatIndex} className='ql-formats'>
                    {renderFormats(formats)}
                </span>
            ))}
        </div>
    );
};

const RichTextVideoComponent: React.FC<EditorProps> = (props) => {
    const quillRef = useRef<any>();
    const [html, setHtml] = useState(props.value);

    const [formats, setFormats] = useState<string[]>([]);
    const [toolbarId, setToolbarId] = useState<string>('');
    const [richTextModules, setRichTextModules] = useState<{
        [key: string]: any;
    }>({});

    /**
     * 富文本上传图片时默认是base64编码，需要将其进行文件上传，并根据上传返回的图片地址显示在富文本中
     */
    const imageHandler = () => {
        let { img } = props;
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('class', 'hide');
        // input.setAttribute('multiple', 'false');
        document.body.appendChild(input);
        input.click();

        const editor = quillRef.current.getEditor();
        input.onchange = (event) => {
            const target = event.target as HTMLInputElement;
            const files = target.files;
            const range = editor.getSelection(true);
            let success = 0;
            let finish = 0;
            //防止清空loading图后索引变化导致删除的位置错误，此处记录并在请求结束后统一进行删除
            let errorIndexs: any = [];
            each(files, (_file, index) => {
                if (!files![index]) return;
                const cursor = index + range.index;
                //在此放置一个过渡用内容，暂时只支持图片形式的过渡
                editor.insertEmbed(
                    cursor,
                    'image',
                    get(img, 'imgPlaceholder', loadingUrl) || loadingUrl
                );
                editor.setSelection(cursor + 1);
                let config: any = {
                    fileName: get(img, 'fileName'),
                    apiRoot: get(img, 'uploadUrl'),
                    apiPath: '',
                };
                services.api
                    .upload({ files: files![index] }, config)
                    .then((response) => {
                        editor.deleteText(cursor, 1);
                        editor.insertEmbed(cursor, 'image', response.path);
                        success++;
                    })
                    .catch(() => {
                        //请求失败后也要清空loading图，防止误解仍在上传中。
                        errorIndexs.push(cursor);
                    })
                    .finally(() => {
                        finish++;
                        if (finish >= files!.length) {
                            //删除时需要从大到小的进行删除，所以先排序
                            each(
                                errorIndexs.sort((before: any, after: any) => after - before),
                                (errorIndex) => {
                                    editor.deleteText(errorIndex, 1);
                                }
                            );
                            //为了确保所有请求完毕后，光标处于正确的位置
                            const cursorIndex = range.index + success;
                            editor.setSelection(cursorIndex);
                            editor.insertEmbed(cursorIndex, '\r\n');
                            editor.setSelection(cursorIndex + 2);
                        }
                    });
            });
        };
        document.body.removeChild(input);
    };

    const videoHandler = (_: any) => {
        const { img, video } = props;
        let fileInfo: any;
        Modal.confirm({
            className: 'rich-text-video-modal',
            title: '上传视频',
            content: (
                <div>
                    <UploadImageVideo
                        uploadType={['video']}
                        maxCount={1}
                        onChange={(value: any[], name: string) => {
                            fileInfo = value[0];
                        }}
                        //@ts-ignore
                        img={{ ...img }}
                        //@ts-ignore
                        video={{ ...video }}
                    />
                </div>
            ),
            onOk: () => {
                videoInsert(fileInfo);
            },
        });
    };

    const videoInsert = (file: any) => {
        if (isEmpty(file)) return;
        const { url, poster, aspectRatio } = file;
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection(true);
        const cursor = range.index;
        editor.setSelection(cursor);
        const config = {
            src: url,
            poster: poster || '',
            aspectRatio: aspectRatio,
        };
        editor.insertEmbed(cursor, 'video', JSON.stringify(config));
        editor.setSelection(cursor + 1);
        editor.insertEmbed(cursor + 1, '\r\n');
        editor.setSelection(cursor + 3);
    };

    useMount(() => {
        setFormats(richTextFormats);
        setToolbarId(generateToolbarId());
    });

    useUpdateEffect(() => {
        setRichTextModules({
            imageResize: {},
            toolbar: {
                container: `#${toolbarId}`,
                handlers: {
                    image: imageHandler,
                    video: videoHandler,
                },
            },
        });
    }, [toolbarId]);

    useEffect(() => {
        setHtml(props.value);
    }, [props.value]);

    useUpdateEffect(() => {
        const { changeValue, name } = props;
        if (changeValue) {
            changeValue(html, name);
        }
    }, [html]);

    const handleChange = (html: any) => {
        setHtml(html);
        const { onChange, name } = props;
        if (onChange) {
            onChange(html, name);
        }
    };

    const handleBlur = (previousRange: any, source: any, editor: any) => {
        const { onBlur, name } = props;

        if (onBlur) {
            onBlur(editor.getHTML(), name);
        }
    };

    const {
        placeholder = services.language.getText('common.placeInput'),
        disabled,
        className,
        style,
    } = props;

    const propsReactQuill = {
        breaks: true,
        theme: disabled ? 'bubble' : 'snow',
        onChange: handleChange,
        onBlur: handleBlur,
        modules: richTextModules,
        formats: formats,
        placeholder: placeholder,
        readOnly: disabled,
        className: className,
        style: style,
        ref: quillRef,
    };
    return (
        <div className='cm-quill-editor'>
            <EditorModules toolbarId={toolbarId} />
            <ReactQuill {...propsReactQuill} value={(html || '').replace(/\n/g, '<br/>')} />
        </div>
    );
};

export const RichTextVideo = React.memo(RichTextVideoComponent);

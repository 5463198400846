import { language } from '@comall-backend-builder/core/lib/services';
import { generateOptions } from '@/services/generate-options';

/**
 * 展示样式类型值
 */
export enum STYLE_PRESENTATION_VALUES {
    /**
     * 普通展示
     */
    GENERA = '1',
    /**
     * 突出展示
     */
    PUTON = '2',
    /**
     * 仅列表突出展示
     */
    HIGHLIGHTED_IN_LIST = '3',
}

/**
 * 展示样式类型名
 */
export const STYLE_PRESENTATION_NAMES = {
    [STYLE_PRESENTATION_VALUES.GENERA]: language.getText(
        'salesPromotion.conditionsReward.generalDisplay'
    ),
    [STYLE_PRESENTATION_VALUES.PUTON]: language.getText(
        'salesPromotion.conditionsReward.putOnDisplay'
    ),
    [STYLE_PRESENTATION_VALUES.HIGHLIGHTED_IN_LIST]: language.getText(
        'salesPromotion.conditionsReward.onlyListHighlighted'
    ),
};

/**
 * 展示样式类型候选项
 */
export const STYLE_PRESENTATION_OPTIONS = generateOptions(
    STYLE_PRESENTATION_VALUES,
    STYLE_PRESENTATION_NAMES
);

/**
 * 换购形式类型值
 */
export enum FORM_EXCHANGE_VALUES {
    /**
     * 单选
     */
    RADIO = '1',
    /**
     * 多选
     */
    MULTISELECT = '2',
}

/**
 * 换购形式类型名
 */
export const FORM_EXCHANGE_NAMES = {
    [FORM_EXCHANGE_VALUES.RADIO]: language.getText('salesPromotion.formExchange.radio'),
    [FORM_EXCHANGE_VALUES.MULTISELECT]: language.getText('salesPromotion.formExchange.multiSelect'),
};

/**
 * 换购形式候选项
 */
export const FORM_EXCHANGE_OPTIONS = generateOptions(FORM_EXCHANGE_VALUES, FORM_EXCHANGE_NAMES);

/**
 * 默认选中类型值
 */
export enum SELECTED_DEFAULT_VALUES {
    /**
     * 是
     */
    YES = '1',
    /**
     * 否
     */
    NO = '0',
}

/**
 * 默认选中类型名
 */
export const SELECTED_DEFAULT_NAMES = {
    [SELECTED_DEFAULT_VALUES.NO]: language.getText('no'),
    [SELECTED_DEFAULT_VALUES.YES]: language.getText('yes'),
};

/**
 * 默认选中候选项
 */
export const SELECTED_DEFAULT_OPTIONS = generateOptions(
    SELECTED_DEFAULT_VALUES,
    SELECTED_DEFAULT_NAMES
);

/**
 * 默认选中类型值
 */
export enum STYLE_COMMODITY_TERMS_VALUES {
    /**
     * 无提示
     */
    NO = '1',
    /**
     * 普通提示
     */
    NORMAL = '2',
    /**
     * 弹出提示
     */
    POPUP_MESSAGE = '3',
}

/**
 * 商品条款样式
 */
export const STYLE_COMMODITY_TERMS_NAMES = {
    [STYLE_COMMODITY_TERMS_VALUES.NO]: language.getText(
        'salesPromotion.styleCommodityTerms.noPrompt'
    ),
    [STYLE_COMMODITY_TERMS_VALUES.NORMAL]: language.getText(
        'salesPromotion.styleCommodityTerms.generalTips'
    ),
    [STYLE_COMMODITY_TERMS_VALUES.POPUP_MESSAGE]: language.getText(
        'salesPromotion.styleCommodityTerms.popUpMessage'
    ),
};

/**
 * 默认选中候选项
 */
export const STYLE_COMMODITY_TERMS_OPTIONS = generateOptions(
    STYLE_COMMODITY_TERMS_VALUES,
    STYLE_COMMODITY_TERMS_NAMES
);

/** 促销类型 */
export enum PROMOTION_TYPE_VALUES {
    /**单品促销 */
    SINGLE = '100',
    /** 多品促销 */
    MULTI = '200',
    /** 订单 */
    ORDER = '300',
}
export const PROMOTION_TYPE_NAMES = {
    [PROMOTION_TYPE_VALUES.SINGLE]: language.getText('salesPromotion.promotionType.single'),
    [PROMOTION_TYPE_VALUES.MULTI]: language.getText('salesPromotion.promotionType.multi'),
    [PROMOTION_TYPE_VALUES.ORDER]: language.getText('salesPromotion.promotionType.order'),
};
export const PROMOTION_TYPE_OPTIONS = generateOptions(PROMOTION_TYPE_VALUES, PROMOTION_TYPE_NAMES);

/** 条件类型 */
export enum TYPE_CONDITION_VALUES {
    /** 无条件 */
    NO = '0',
    /** 件数 */
    COUNT = '2',
    /** 金额 */
    MONEY = '1',
}
export const TYPE_CONDITION_NAMES = {
    [TYPE_CONDITION_VALUES.NO]: language.getText('salesPromotion.typeCondition.no'),
    [TYPE_CONDITION_VALUES.COUNT]: language.getText('salesPromotion.typeCondition.count'),
    [TYPE_CONDITION_VALUES.MONEY]: language.getText('salesPromotion.typeCondition.money'),
};
export const TYPE_CONDITION_OPTIONS = generateOptions(TYPE_CONDITION_VALUES, TYPE_CONDITION_NAMES);

/** 奖励类型 */
export enum REWARD_TYPE_VALUES {
    /**直折 */
    DISCOUNT = '1',
    /** 直减 */
    MONEY = '2',
    /** 加价选购 */
    CHOOSE_PRODUCT = '4',
    /** 赠品 */
    GIFT = '3',
    /** 满减/阶梯满减 */
    STEP_MONEY = '5',
    /** 满折/阶梯满折 */
    STEP_DISCOUNT = '6',
    /** 每满减 */
    SATISFY_MONEY = '7',
}
export const REWARD_TYPE_NAMES = {
    [REWARD_TYPE_VALUES.DISCOUNT]: language.getText('salesPromotion.rewardType.discount'),
    [REWARD_TYPE_VALUES.MONEY]: language.getText('salesPromotion.rewardType.money'),
    [REWARD_TYPE_VALUES.CHOOSE_PRODUCT]: language.getText(
        'salesPromotion.rewardType.chooseProduct'
    ),
    [REWARD_TYPE_VALUES.GIFT]: language.getText('salesPromotion.rewardType.gift'),
    [REWARD_TYPE_VALUES.STEP_MONEY]: language.getText('salesPromotion.rewardType.stepMoney'),
    [REWARD_TYPE_VALUES.STEP_DISCOUNT]: language.getText('salesPromotion.rewardType.stepDiscount'),
    [REWARD_TYPE_VALUES.SATISFY_MONEY]: language.getText('salesPromotion.rewardType.satisfyMoney'),
};
export const REWARD_TYPE_OPTIONS = generateOptions(REWARD_TYPE_VALUES, REWARD_TYPE_NAMES);

/**
 * 换购商品组类型值
 */
export enum MERCHANDISE_GROUP_VALUES {
    /**
     * 同主组
     */
    SAME = '1',
    /**
     * 额外分组
     */
    ADDITIONAL = '2',
}

/**
 * 换购商品组类型名
 */
export const MERCHANDISE_GROUP_NAMES = {
    [MERCHANDISE_GROUP_VALUES.SAME]: language.getText(
        'salesPromotion.merchandiseGroup.sameMainGroup'
    ),
    [MERCHANDISE_GROUP_VALUES.ADDITIONAL]: language.getText(
        'salesPromotion.merchandiseGroup.additionalGrouping'
    ),
};

/**
 * 换购商品组候选项
 */
export const MERCHANDISE_GROUP_OPTIONS = generateOptions(
    MERCHANDISE_GROUP_VALUES,
    MERCHANDISE_GROUP_NAMES
);

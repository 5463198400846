import React, { Component } from 'react';
import {
    Button as AntButton,
    Modal as AntModal,
    message as AntMessage,
    Form as AntForm,
    Table as AntTable,
    InputNumber,
    Input,
} from 'antd';
// import { trim, map } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { OptionType } from '@/interfaces';
import { language } from '@comall-backend-builder/core/lib/services';
import SelectAutoComplete from '@/components/select-auto-complete';
import AccessModal from '@/containers/offline-order/offline-create-basic-form/access-modal';
import { isEmpty } from 'lodash';

interface PayInfo {
    key: string;
    paymentType: string;
    paidAmountNum: string;
    paidAmount: string;
    time: string;
}
interface IProps {
    id: string;
    outerOrderNumber: string;
    parentProps: any;
    payableAmount: string;
}
interface IState {
    confirmLoading: boolean;
    visible: boolean;
    paymentType: OptionType | undefined;
    paymentAmount: number | undefined;
    paymentSerialNo: string | undefined;
    options: Array<OptionType>;
    validatePaymentType: {
        status: 'error' | undefined;
        help: string | undefined;
    };
    validatePaymentAmount: {
        status: 'error' | undefined;
        help: string | undefined;
    };
    validatePaymentStatement: {
        status: 'error' | undefined;
        help: string | undefined;
    };
    payInfoList: PayInfo[];
    paymentTypeRes: AnyObject[];
}

export class AddPaymentInfoModal extends Component<IProps, IState> {
    state: IState = {
        confirmLoading: false,
        visible: false,
        paymentType: undefined,
        paymentAmount: undefined,
        paymentSerialNo: undefined,
        validatePaymentType: {
            status: undefined,
            help: undefined,
        },
        validatePaymentAmount: {
            status: undefined,
            help: undefined,
        },
        validatePaymentStatement: {
            status: undefined,
            help: undefined,
        },
        options: [],
        payInfoList: [],
        paymentTypeRes: [],
    };

    componentWillMount = () => {
        this.getPaymentType();
    };

    getPaymentType = async () => {
        try {
            const { result }: any = await services.api.get(
                { page: 1, perPage: 20 },
                { apiPath: '/dc-goods/admin/merchant' }
            );
            this.setState({
                options: result.map((item: any) => ({
                    id: item.id,
                    name: item.nameMap?.zh,
                })) as OptionType[],
            });
        } catch (error) {}
    };

    toggleModal = (flag: boolean) => {
        this.setState({
            visible: flag,
        });
    };

    handleCancel = () => {
        this.toggleModal(false);
    };

    handleClose = () => {
        const {
            parentProps: { entity },
        } = this.props;
        entity.search(entity.params);
    };
    /** 外层添加按钮点击 */
    handleOpen = () => {
        this.toggleModal(true);
        this.getPayInfoList();
    };

    /** 获取支付信息 */
    getPayInfoList = () => {
        const { id } = this.props;

        this.setState({ confirmLoading: true });
        services.api
            .get(
                {},
                {
                    apiPath: `/base-order/admin/order/find_payment_records/${id}`,
                }
            )
            .then((res: any) => {
                const payInfoList = res.map((i: any) => ({
                    key: i.id,
                    paymentType: i.paymentTypeName,
                    paidAmountNum: i.paidAmount,
                    paidAmount: i.paidAmountStr,
                    paymentSerialNo: i.paymentSerialNo,
                    time: i.paidTime,
                }));
                this.setState({
                    payInfoList,
                });
            })
            .catch(services.errorHandle)
            .finally(() => {
                this.setState({ confirmLoading: false });
            });
    };

    render() {
        const { confirmLoading, paymentAmount, paymentSerialNo, visible } = this.state;
        const { id } = this.props;
        const columns = [
            {
                title: language.getText('organizationNumber'),
                render: (text: any, record: any, index: number) => `${index + 1}`,
                key: 'organizationNumber',
            },
            {
                title: language.getText('order.payMode'),
                dataIndex: 'paymentType',
                key: 'paymentType',
            },
            {
                title: language.getText('paymentDetail.amount'),
                dataIndex: 'paidAmount',
                key: 'paidAmount',
            },
            {
                title: language.getText('paymentDetail.time'),
                dataIndex: 'time',
                key: 'time',
            },
            {
                title: language.getText('offlineOrder.paymentSerialNo'),
                dataIndex: 'paymentSerialNo',
                key: 'paymentSerialNo',
            },
            {
                title: language.getText('actions'),
                key: 'action',
                render: (text: any, record: any, index: number) => (
                    <span>
                        <a href={'/#'} onClick={(e) => deletePayInfo(text, record, e)}>
                            {language.getText('delete')}
                        </a>
                    </span>
                ),
            },
        ];

        const deleteItem = (record: any, accessCode?: string) => {
            this.setState({ confirmLoading: true });
            services.api
                .put(
                    {
                        recordId: record.key,
                        accessCode: accessCode,
                    },
                    {
                        apiPath: `/base-order/admin/order/${id}/offline_del_payment_record`,
                    }
                )
                .then(() => {
                    this.getPayInfoList();
                })
                .catch(services.errorHandle)
                .finally(() => {
                    this.setState({ confirmLoading: false });
                });
        };

        const deletePayInfo = (_: any, record: any, e: any) => {
            e.preventDefault();
            const userJob = window.sessionStorage.getItem('userJob');
            const userJobKey = userJob && JSON.parse(userJob)?.key;
            if (userJobKey === 1) {
                deleteItem(record);
            } else {
                let accessCode = '';
                const getValue = (value: string) => {
                    accessCode = value;
                };
                AntModal.confirm({
                    icon: null,
                    title: (
                        <div style={{ textAlign: 'center' }}>
                            {services.language.getText('offlineOrder.requiredAuthorization')}
                        </div>
                    ),
                    content: <AccessModal getValue={getValue} />,
                    onOk() {
                        return deleteItem(record, accessCode);
                    },
                });
            }
        };

        //添加商品信息表单
        const getAddForm = () => {
            const onChangePaymentType = (value: OptionType) => {
                this.setState({
                    paymentType: value,
                });
            };

            const onChangePaymentAmount = (e: number | undefined) => {
                this.setState({
                    paymentAmount: e,
                });
            };

            const onChangePaymentStatement = (e: any) => {
                this.setState({
                    paymentSerialNo: e.target.value,
                });
            };

            const clearPayInfo = () => {
                this.setState({
                    paymentType: undefined,
                    paymentAmount: undefined,
                    paymentSerialNo: undefined,
                });
            };

            const addPayInfo = () => {
                let allow = true;
                if (this.state.paymentType) {
                    this.setState({
                        validatePaymentType: {
                            status: undefined,
                            help: undefined,
                        },
                    });
                } else {
                    this.setState({
                        validatePaymentType: {
                            status: 'error',
                            help: language.getText('offlineOrder.tips.paymentType'),
                        },
                    });
                    allow = false;
                }
                if (this.state.paymentAmount) {
                    this.setState({
                        validatePaymentAmount: {
                            status: undefined,
                            help: undefined,
                        },
                    });
                } else {
                    this.setState({
                        validatePaymentAmount: {
                            status: 'error',
                            help: language.getText('offlineOrder.tips.paymentAmount'),
                        },
                    });
                    allow = false;
                }

                const regex = /^[a-zA-Z0-9]*$/;
                if (!this.state.paymentSerialNo || regex.test(this.state.paymentSerialNo)) {
                    this.setState({
                        validatePaymentStatement: {
                            status: undefined,
                            help: undefined,
                        },
                    });
                } else {
                    this.setState({
                        validatePaymentStatement: {
                            status: 'error',
                            help: language.getText('offlineOrder.tips.paymentSerialNo'),
                        },
                    });
                    allow = false;
                }

                if (!allow) return;
                this.setState({ confirmLoading: true });

                const paymentObj = this.state.paymentTypeRes.find(
                    (item) => item.id.toString() === this.state.paymentType?.id
                );

                const paymentRecordPrm = [
                    {
                        paid_amount: this.state.paymentAmount,
                        payment_type: paymentObj?.code,
                        paymentSerialNo: this.state.paymentSerialNo,
                        paymentModeId: paymentObj?.id,
                    },
                ];
                services.api
                    .put(
                        { items: paymentRecordPrm },
                        {
                            apiPath: `/base-order/admin/order/${id}/offline_add_payment_record`,
                        }
                    )
                    .then(() => {
                        this.getPayInfoList();
                        clearPayInfo();
                        AntMessage.success(
                            services.language.getText('offlineOrder.tips.addPayInfoSuccess')
                        );
                    })
                    .catch(services.errorHandle)
                    .finally(() => {
                        this.setState({ confirmLoading: false });
                    });
            };

            return (
                <>
                    <AntForm layout='inline'>
                        <AntForm.Item
                            label={language.getText('offlineOrder.paymentType')}
                            validateStatus={this.state.validatePaymentType.status}
                            help={this.state.validatePaymentType.help}
                        >
                            <SelectAutoComplete
                                value={this.state.paymentType}
                                style={{ width: '170px' }}
                                placeholder={services.language.getText('defaultPlaceholderSelect')}
                                apiPath='/base-order/admin/payment_type/byStoreId'
                                params={{
                                    onlineStoreId: (this.props as AnyObject)?.parentProps?.entity
                                        ?.params?.onlineStoreIds,
                                }}
                                formatResponse={(
                                    response: [{ internalName: string; id: string }]
                                ) => {
                                    if (isEmpty(response)) return [];
                                    this.setState({ paymentTypeRes: response });
                                    return response.map((item) => ({
                                        name: item.internalName,
                                        id: item.id,
                                    }));
                                }}
                                onChange={(val: any) => {
                                    onChangePaymentType(val);
                                }}
                                selectParamKey='keyword'
                            />
                            {/* <Select
                            allowClear
                            placeholder={language.getText('defaultPlaceholderSelect')}
                            onChange={onChangePaymentType}
                            value={paymentType?.id}
                        >
                            {map(options, (item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select> */}
                        </AntForm.Item>
                        <AntForm.Item
                            label={language.getText('offlineOrder.paymentAccount')}
                            validateStatus={this.state.validatePaymentAmount.status}
                            help={this.state.validatePaymentAmount.help}
                        >
                            <InputNumber
                                style={{ width: '170px', marginRight: '15px' }}
                                type='number'
                                min={0}
                                max={99999999}
                                precision={2}
                                placeholder={language.getText(
                                    'offlineOrder.placeholder.payAccount'
                                )}
                                onChange={(val) => onChangePaymentAmount(val)}
                                value={paymentAmount}
                            />
                        </AntForm.Item>
                        <AntForm.Item>
                            <AntButton type='primary' onClick={addPayInfo}>
                                {language.getText('offlineOrder.addPayInfo')}
                            </AntButton>
                        </AntForm.Item>
                    </AntForm>
                    <AntForm layout='inline' style={{ marginBottom: 15 }}>
                        <AntForm.Item
                            label={language.getText('offlineOrder.paymentSerialNo')}
                            validateStatus={this.state.validatePaymentStatement.status}
                            help={this.state.validatePaymentStatement.help}
                        >
                            <Input
                                style={{ width: '425px', marginRight: '15px' }}
                                maxLength={50}
                                placeholder={language.getText('common.pleaseInput')}
                                onChange={(val) => onChangePaymentStatement(val)}
                                value={paymentSerialNo}
                            />
                        </AntForm.Item>
                    </AntForm>
                </>
            );
        };

        return (
            <>
                <AntButton style={{ marginRight: 15 }} onClick={this.handleOpen}>
                    {language.getText('offlineOrder.addPaymentInfo')}
                </AntButton>
                <AntModal
                    width={800}
                    title={language.getText('offlineOrder.addPaymentInfo')}
                    visible={visible}
                    confirmLoading={confirmLoading}
                    afterClose={this.handleClose}
                    onCancel={this.handleCancel}
                    footer={[
                        <AntButton type='primary' onClick={this.handleCancel}>
                            {language.getText('completed')}
                        </AntButton>,
                    ]}
                >
                    <div style={{ margin: '10px 0 10px' }}>
                        {language.getText('offlineOrder.waitPayAccount')}:{' '}
                        {this.props.payableAmount}
                    </div>
                    <div style={{ margin: '10px 0 10px' }}>
                        {language.getText('offlineOrder.confirmPayAccount')}:{' '}
                        {this.state.payInfoList
                            .reduce((p, c) => p + Number(c.paidAmountNum), 0)
                            .toFixed(2)}
                    </div>
                    {getAddForm()}
                    <AntTable
                        columns={columns}
                        dataSource={this.state.payInfoList}
                        pagination={false}
                    />
                </AntModal>
            </>
        );
    }
}

import React, { Fragment, useEffect, useRef } from 'react';
import {
    Form as AntdForm,
    DatePicker,
    Input as AntInput,
    InputNumber,
    Radio,
    Checkbox,
    Select,
} from 'antd';
import { debounce, get, isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { AsyncTreeSelect } from '@/components/async-tree-select';
import SelectAutoComplete from '@/components/select-auto-complete';
import moment from 'moment';
import {
    DELIVERY_FLAG_OPTIONS,
    DESIGN_MANAGE_LANGUAGE_NAMES,
    DESIGN_MANAGE_LANGUAGE_VALUES,
    PRODUCT_SALE_CHANNEL_OPTIONS,
    PRODUCT_SKU_STATUS_OPTIONS,
} from '@/constants';
import { GroupItems } from './group-items';
import './index.less';
const timeBeforeOptions = [
    { label: '是', value: true },
    { label: '否', value: false },
];
const stockOutOptions = [
    { label: '是', value: true },
    { label: '否', value: false },
];
const installmentPaymentOptions = [
    { label: '是', value: true },
    { label: '否', value: false },
];
const contentMap = [{ key: 'en' }, { key: 'zh' }, { key: 'tc' }];

interface BasicProps {
    params?: any;
    form?: any;
}
const BasicMessageForm: React.FC<BasicProps> = (props: any) => {
    const categoryIdRef = useRef();
    const isDetail = useRef(services.navigation.getPathname().split('/')[3] === 'detail');
    useEffect(() => {
        if (categoryIdRef.current && isEmpty(get(categoryIdRef, 'current.state.options'))) {
            //@ts-ignore
            categoryIdRef.current.onLoadData({ props: 0 });
        }
    }, []);
    const { width, length, height, size, fromCskuId } = props.form.getFieldsValue();
    const volumeFn = debounce((width, length, height) => {
        const volume = width * length * height;
        props.form.setFieldsValue({
            volume: (volume / 1000).toFixed(2),
        });
    }, 200);
    //计算商品体积
    useEffect(() => {
        if (size === 2) {
            volumeFn(width, length, height);
        }
        if (size === 1) {
            props.form.setFieldsValue({ width: '', length: '', height: '' });
        }
        // eslint-disable-next-line
    }, [width, length, height, size]);

    useEffect(() => {
        if (!isEmpty(fromCskuId)) {
            props.loadDetail(fromCskuId.id, { fromCskuId: fromCskuId });
        }
        // eslint-disable-next-line
    }, [fromCskuId]);
    return (
        <Fragment>
            {props.params.id && (
                <AntdForm.Item required label={services.language.getText('productList.csku')}>
                    {props.form.getFieldDecorator('csku')(<AntInput disabled />)}
                </AntdForm.Item>
            )}
            {/* 商品条码 */}
            <AntdForm.Item label={services.language.getText('productAdd.basicMessage.barcode')}>
                {props.form.getFieldDecorator('barCode', {
                    rules: [
                        {
                            required: true,
                            message: services.language.getText(
                                'productAdd.basicMessage.barcodeRule'
                            ),
                        },
                    ],
                })(
                    <AntInput
                        disabled={isDetail.current}
                        placeholder={services.language.getText(
                            'productAdd.basicMessage.barcodePlaceholder'
                        )}
                    />
                )}
            </AntdForm.Item>
            {/* 复制商品信息 */}
            <AntdForm.Item
                label={services.language.getText('productAdd.basicMessage.copyProductInfo')}
            >
                {props.form.getFieldDecorator('fromCskuId')(
                    <SelectAutoComplete
                        disabled={isDetail.current}
                        placeholder={services.language.getText('defaultPlaceholderSelect')}
                        apiPath='/loader/dc-goods/admin/cskus/queryCskuByPage'
                        params={{ page: 1, perPage: 10, sourceTypeStr: props.sourceType }}
                        selectParamKey='keyword'
                    />
                )}
            </AntdForm.Item>
            {/* 商品名称 */}
            {contentMap.map((item, index) => {
                return (
                    <AntdForm.Item
                        label={`${services.language.getText(
                            'productAdd.basicMessage.productName'
                        )}（${
                            DESIGN_MANAGE_LANGUAGE_NAMES[
                                `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                            ]
                        }）`}
                        key={index}
                    >
                        {props.form.getFieldDecorator(`productNameMap.${item.key}`, {
                            rules: [
                                {
                                    required: true,
                                    message: `${services.language.getText(
                                        'defaultPlaceholderInput'
                                    )}${services.language.getText(
                                        'productAdd.basicMessage.productName'
                                    )}（${
                                        DESIGN_MANAGE_LANGUAGE_NAMES[
                                            `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                                        ]
                                    }）`,
                                },
                            ],
                        })(
                            <AntInput
                                disabled={isDetail.current}
                                placeholder={services.language.getText(
                                    'productAdd.basicMessage.productNamePlaceholder'
                                )}
                                maxLength={100}
                            />
                        )}
                    </AntdForm.Item>
                );
            })}
            {/* 商品品牌 */}
            <AntdForm.Item
                label={services.language.getText('productAdd.basicMessage.productBrand')}
            >
                {props.form.getFieldDecorator('brandId', {
                    rules: [
                        {
                            required: true,
                            message: services.language.getText(
                                'productAdd.basicMessage.productBrandRule'
                            ),
                        },
                    ],
                })(
                    <SelectAutoComplete
                        disabled={isDetail.current}
                        placeholder={services.language.getText('defaultPlaceholderSelect')}
                        apiPath='/dc-goods/admin/brands'
                        params={{ page: 1, perPage: 10 }}
                        selectParamKey='keyword'
                        formatResponse={(response: any) => {
                            let result = response.result.map((item: any) => ({
                                id: item.id,
                                name: item.name,
                            }));
                            return result;
                        }}
                    />
                )}
            </AntdForm.Item>
            {/* 标准分类 */}
            <AntdForm.Item
                label={services.language.getText('productAdd.basicMessage.standardClassify')}
                help={services.language.getText('productAdd.basicMessage.standardClassifyWarn')}
            >
                {props.form.getFieldDecorator('categoryId', {
                    rules: [
                        {
                            required: true,
                            message: '更换标准分类会导致以维护的商品分类特有属性丢失，请谨慎操作',
                        },
                    ],
                })(
                    <AsyncTreeSelect
                        ref={categoryIdRef}
                        disabled={isDetail.current}
                        name='categoryId'
                        source={{
                            apiPath: '/loader/dc-goods/admin/offline_categories/tree_no_root',
                        }}
                        params={{ id: '-2' }}
                        rootId='-2'
                        {...props}
                    />
                )}
            </AntdForm.Item>
            {/* 商品重量 */}
            {props.sourceType !== '3' && (
                <>
                    <AntdForm.Item
                        label={services.language.getText('productAdd.basicMessage.productWeight')}
                    >
                        {props.form.getFieldDecorator('weight')(
                            <InputNumber
                                placeholder={services.language.getText('defaultPlaceholderInput')}
                                style={{ width: '400px' }}
                                disabled={isDetail.current}
                                min={0.01}
                                precision={2}
                                max={99999999}
                            />
                        )}
                        <span style={{ marginLeft: '20px' }}>g</span>
                    </AntdForm.Item>
                    {/* 商品体积 */}
                    <AntdForm.Item
                        label={services.language.getText('productAdd.basicMessage.productVolume')}
                    >
                        {props.form.getFieldDecorator('size', { initialValue: 1 })(
                            <Radio.Group name='size' style={{ marginTop: '5px' }}>
                                <Radio value={1}>
                                    <span>
                                        {services.language.getText(
                                            'productAdd.basicMessage.volume'
                                        )}
                                    </span>
                                    {props.form.getFieldDecorator('volume')(
                                        <InputNumber
                                            placeholder={services.language.getText(
                                                'defaultPlaceholderInput'
                                            )}
                                            style={{ marginLeft: '40px', width: '258px' }}
                                            disabled={
                                                props.form.getFieldValue('size') !== 1 ||
                                                isDetail.current
                                            }
                                            precision={2}
                                        />
                                    )}
                                    <span style={{ marginLeft: '20px' }}>L</span>
                                </Radio>
                                <Radio style={{ marginTop: '15px' }} value={2}>
                                    <span>
                                        {services.language.getText(
                                            'productAdd.basicMessage.lengthWideHigh'
                                        )}
                                    </span>
                                    {props.form.getFieldDecorator('length')(
                                        <InputNumber
                                            placeholder={services.language.getText(
                                                'productAdd.basicMessage.length'
                                            )}
                                            style={{
                                                marginLeft: 25,
                                                marginBottom: '10px',
                                                width: '80px',
                                            }}
                                            type='number'
                                            min={0.01}
                                            precision={2}
                                            max={99999999}
                                            disabled={
                                                props.form.getFieldValue('size') !== 2 ||
                                                isDetail.current
                                            }
                                        />
                                    )}
                                    {props.form.getFieldDecorator('width')(
                                        <InputNumber
                                            placeholder={services.language.getText(
                                                'productAdd.basicMessage.wide'
                                            )}
                                            style={{
                                                marginLeft: 10,
                                                marginBottom: '10px',
                                                width: '80px',
                                            }}
                                            type='number'
                                            min={0.01}
                                            precision={2}
                                            max={99999999}
                                            disabled={
                                                props.form.getFieldValue('size') !== 2 ||
                                                isDetail.current
                                            }
                                        />
                                    )}
                                    {props.form.getFieldDecorator('height')(
                                        <InputNumber
                                            placeholder={services.language.getText(
                                                'productAdd.basicMessage.high'
                                            )}
                                            style={{
                                                marginLeft: 10,
                                                marginBottom: '10px',
                                                width: '80px',
                                            }}
                                            type='number'
                                            min={0.01}
                                            precision={2}
                                            max={99999999}
                                            disabled={
                                                props.form.getFieldValue('size') !== 2 ||
                                                isDetail.current
                                            }
                                        />
                                    )}
                                    <span style={{ marginLeft: '20px' }}>CM</span>
                                </Radio>
                            </Radio.Group>
                        )}
                    </AntdForm.Item>
                </>
            )}
            {/* 规格类型 */}
            <AntdForm.Item label={services.language.getText('productAdd.basicMessage.specsType')}>
                {props.form.getFieldDecorator('specsType', {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    initialValue: 0,
                })(
                    <Radio.Group
                        name='specsType'
                        style={{ marginTop: '9px' }}
                        className='specsType'
                        disabled={isDetail.current || props.config.type === 'edit'}
                    >
                        <Radio value={0}>
                            <span>
                                {services.language.getText('productAdd.basicMessage.common')}
                            </span>
                            {contentMap.map((item, index) => {
                                return (
                                    <AntdForm.Item
                                        label={`${services.language.getText(
                                            'productAdd.basicMessage.specifications'
                                        )}（${
                                            DESIGN_MANAGE_LANGUAGE_NAMES[
                                                `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                                            ]
                                        }）`}
                                        key={index}
                                        labelCol={{ span: 8, offset: 1 }}
                                        wrapperCol={{ span: 10 }}
                                    >
                                        {props.form.getFieldDecorator(`specsMap.${item.key}`, {
                                            rules: [
                                                {
                                                    required:
                                                        props.form.getFieldValue('specsType') === 0,
                                                    message: `请输入${services.language.getText(
                                                        'productAdd.basicMessage.specifications'
                                                    )}（${
                                                        DESIGN_MANAGE_LANGUAGE_NAMES[
                                                            `${item.key}` as DESIGN_MANAGE_LANGUAGE_VALUES
                                                        ]
                                                    }）`,
                                                },
                                            ],
                                        })(
                                            <AntInput
                                                disabled={
                                                    props.form.getFieldValue('specsType') !== 0 ||
                                                    isDetail.current ||
                                                    props.config.type === 'edit'
                                                }
                                                placeholder={services.language.getText(
                                                    'productAdd.basicMessage.specificationsPlaceholder'
                                                )}
                                                maxLength={10}
                                            />
                                        )}
                                    </AntdForm.Item>
                                );
                            })}
                        </Radio>
                        <Radio
                            style={{
                                marginTop: '15px',
                                display: props.sourceType === '0' ? 'block' : 'none',
                            }}
                            value={1}
                        >
                            <span>
                                {services.language.getText('productAdd.basicMessage.weighing')}
                            </span>
                            {[{ key: 0 }, { key: 1 }, { key: 2 }].map((item) => {
                                return (
                                    <AntdForm.Item
                                        label={services.language.getText(
                                            `productAdd.basicMessage.specifications${item.key + 1}`
                                        )}
                                        key={item.key}
                                        labelCol={{ span: 8, offset: 1 }}
                                        wrapperCol={{ span: 10 }}
                                        required={item.key === 0}
                                    >
                                        {props.form.getFieldDecorator(`sellSpecs.${item.key}`, {
                                            rules: [
                                                {
                                                    required:
                                                        props.form.getFieldValue('specsType') ===
                                                            1 && item.key === 0,
                                                    message: '请输入售卖规格',
                                                },
                                            ],
                                        })(
                                            <InputNumber
                                                disabled={
                                                    props.form.getFieldValue('specsType') !== 1 ||
                                                    isDetail.current ||
                                                    props.config.type === 'edit'
                                                }
                                                placeholder={`${services.language.getText(
                                                    'defaultPlaceholderInput'
                                                )}（100）`}
                                                style={{ marginLeft: 45, marginBottom: '10px' }}
                                                type='number'
                                                min={1}
                                                precision={0}
                                                max={99999999}
                                            />
                                        )}
                                        {/* <span style={{ marginLeft: '9px' }}>g</span> */}
                                    </AntdForm.Item>
                                );
                            })}
                        </Radio>
                    </Radio.Group>
                )}
            </AntdForm.Item>
            {/* 运输方式 */}
            <AntdForm.Item label={services.language.getText('productAdd.basicMessage.modeType')}>
                {props.form.getFieldDecorator('modeType', {
                    rules: [
                        {
                            type: 'array',
                            required: true,
                            message: '请选择运输方式',
                        },
                    ],
                })(<Checkbox.Group options={DELIVERY_FLAG_OPTIONS} disabled={isDetail.current} />)}
            </AntdForm.Item>
            {/* 商品状态 */}
            <AntdForm.Item label={services.language.getText('productAdd.basicMessage.earlyDate')}>
                <span>下单</span>
                {props.form.getFieldDecorator(
                    'deliveryDate',
                    {}
                )(
                    <InputNumber
                        placeholder={services.language.getText('defaultPlaceholderSelect')}
                        style={{ marginLeft: 10, marginBottom: '10px', width: '80px' }}
                        type='number'
                        min={0}
                        max={99999999}
                        disabled={isDetail.current}
                    />
                )}
                <span>天后</span>
            </AntdForm.Item>
            {/* 商品状态 */}
            <AntdForm.Item label={services.language.getText('productAdd.basicMessage.goodsState')}>
                {props.form.getFieldDecorator('skuStatus', {
                    rules: [
                        {
                            required: true,
                            message: '请选择',
                        },
                    ],
                })(
                    <Select
                        disabled={isDetail.current}
                        showSearch
                        placeholder={services.language.getText('defaultPlaceholderSelect')}
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                            //@ts-ignore
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {PRODUCT_SKU_STATUS_OPTIONS.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </AntdForm.Item>
            {/*合作商户 */}
            <AntdForm.Item
                label={services.language.getText('productAdd.basicMessage.merchantName')}
            >
                {props.form.getFieldDecorator('merchantId', {
                    rules: [
                        {
                            required: true,
                            message: '请选择',
                        },
                    ],
                })(
                    //@ts-ignore
                    <SelectAutoComplete
                        disabled={isDetail.current}
                        placeholder={services.language.getText('defaultPlaceholderSelect')}
                        apiPath='/dc-goods/admin/merchant'
                        params={{ page: 1, perPage: 10 }}
                        selectParamKey='keyword'
                        formatResponse={(response: any) => {
                            let result = response.result.map((item: any) => ({
                                id: item.id,
                                name: item.nameMap.zh,
                            }));
                            return result;
                        }}
                    />
                )}
            </AntdForm.Item>
            {/* 商品有效期 */}
            <AntdForm.Item label={services.language.getText('productAdd.basicMessage.goodsTime')}>
                {props.form.getFieldDecorator('validity', {
                    initialValue: { start: null, end: null },
                })(
                    //@ts-ignore
                    <DataTimeRange disabled={isDetail.current} />
                )}
            </AntdForm.Item>
            {/* 商品有效期前展示 */}
            <AntdForm.Item label={services.language.getText('productAdd.basicMessage.timeBefore')}>
                {props.form.getFieldDecorator('isShow', { initialValue: true })(
                    <Radio.Group options={timeBeforeOptions} disabled={isDetail.current} />
                )}
            </AntdForm.Item>
            {/* 缺货售卖 */}
            <AntdForm.Item label={services.language.getText('productAdd.basicMessage.stockout')}>
                {props.form.getFieldDecorator('stockOut', { initialValue: false })(
                    <Radio.Group options={stockOutOptions} disabled={isDetail.current} />
                )}
            </AntdForm.Item>
            {/* 售卖渠道 */}
            <AntdForm.Item
                label={services.language.getText('productAdd.basicMessage.salesChannels')}
            >
                {props.form.getFieldDecorator('salesChannels', {
                    rules: [
                        {
                            type: 'array',
                            required: true,
                            message: services.language.getText('defaultPlaceholderSelect'),
                        },
                    ],
                })(
                    <Checkbox.Group
                        options={PRODUCT_SALE_CHANNEL_OPTIONS}
                        disabled={isDetail.current}
                    />
                )}
            </AntdForm.Item>
            {/* 分期付款 */}
            <AntdForm.Item
                label={services.language.getText('productAdd.basicMessage.installmentPayment')}
            >
                {props.form.getFieldDecorator('installmentPayment', { initialValue: false })(
                    <Radio.Group options={installmentPaymentOptions} disabled={isDetail.current} />
                )}
            </AntdForm.Item>
            {props.sourceType === '2' && (
                <AntdForm.Item
                    label={services.language.getText('productAdd.basicMessage.selectProduct')}
                >
                    {props.form.getFieldDecorator('cskuGroups', {
                        rules: [
                            {
                                validator: function(rule: any, value: any, callback: any) {
                                    if (value.some((i: any) => !i.quantity)) {
                                        callback(
                                            services.language.getText(
                                                'productAdd.basicMessage.cskuGroupsError'
                                            )
                                        );
                                    }
                                    callback();
                                },
                            },
                            {
                                required: true,
                                message: services.language.getText('defaultPlaceholderSelect'),
                            },
                        ],
                    })(<GroupItems disabled={isDetail.current} sourceType={props.sourceType} />)}
                </AntdForm.Item>
            )}
        </Fragment>
    );
};

export default BasicMessageForm;

const DataTimeRange = (props: any) => {
    const { value, onChange, disabled } = props;
    const { start, end } = value;
    const change = (val: string, type: 'start' | 'end') => {
        onChange({ ...value, [type]: val });
    };
    return (
        <div style={{ display: 'flex' }}>
            <DatePicker
                disabled={disabled}
                showTime
                format='YYYY-MM-DD HH:mm:ss'
                value={start && moment(start)}
                placeholder='开始时间'
                onChange={(value, dateString) => change(dateString, 'start')}
            />
            <DatePicker
                style={{ marginLeft: '24px' }}
                disabled={disabled}
                showTime
                format='YYYY-MM-DD HH:mm:ss'
                value={end && moment(end)}
                placeholder='结束时间（不填表长期）'
                onChange={(value, dateString) => change(dateString, 'end')}
            />
        </div>
    );
};

/*
 * @Author: zhulu
 * @Date: 2022-05-26 16:42:59
 * @Description: 虚拟分类或者展示分类的商品列表
 */
import { Config, ComponentMetadata } from '@comall-backend-builder/core/lib/parser';
import { cloneDeep } from 'lodash';
import {
    tableMixin,
    flexibleFilterForm,
    getLanguageProperties,
    getLanguageColumns,
} from '@/configs/mixin';
import { CATEGORY_PRODUCT_LIST } from '@/configs/pro-table-constants';
import { PRODUCT_SOURCE_TYPE_OPTIONS, SPECS_TYPE_OPTIONS } from '@/constants';

const entityProperties = {
    pictureUrl: {
        type: 'string.image.text',
        displayName: '<<productList.heroImagePath>>',
    },
    specsType: {
        type: 'string.options.radio',
        displayName: '<<productList.specsType>>',
        options: SPECS_TYPE_OPTIONS,
    },

    name: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'productList.productName') },
    },
    csku: {
        type: 'string',
        displayName: '<<productList.csku>>',
    },
    brandName: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'productList.brandName') },
    },
    offlineCategoryName: {
        type: 'string.format',
        displayName: '<<productList.saleCategory>>',
    },
    sourceType: {
        type: 'string.options.select',
        displayName: '<<productList.productSource>>',
        options: PRODUCT_SOURCE_TYPE_OPTIONS,
    },
};
const entityFilters = {
    keyword: {
        type: 'string',
        displayName: '<<keyword>>',
    },
    cskus: {
        type: 'string',
        displayName: '<<productList.csku>>',
    },
    offlineCategoryId: {
        multiple: false,
        type: 'array.treeSelect.async',
        displayName: '<<displayCategory.saleCategories>>',
        options: [],
        source: {
            apiPath: '/loader/dc-goods/admin/offline_categories/tree_no_root',
        },
        params: { id: '-2' },
        rootId: '-2',
        controlConfig: {
            placeholder: '<<defaultPlaceholderSelect>>',
            allowClear: true,
        },
    },
    sourceType: {
        type: 'string.options.select',
        displayName: '<<productList.productSource>>',
        options: PRODUCT_SOURCE_TYPE_OPTIONS,
    },
    brandId: {
        type: 'string.options.autoComplete',
        displayName: '<<productList.brand>>',
        options: [],
        source: {
            apiPath: '/loader/dc-goods/admin/brands/list',
        },
    },
};
const filterProperties = [
    { property: 'keyword' },
    { property: 'cskus' },
    { property: 'brandId' },
    // { property: 'offlineCategoryId' },
    { property: 'sourceType' },
];
enum Source2Router {
    'normal' = 0,
    'group' = 2,
    'virtual' = 3,
}
const componentConfig: {
    [name: string]: ComponentMetadata;
} = {
    content: {
        component: 'FlexLayout',
        direction: 'vertical',
        items: [
            { ...flexibleFilterForm, fields: filterProperties },
            {
                ...tableMixin,
                component: 'ProTable',
                componentName: CATEGORY_PRODUCT_LIST,
                scroll: {
                    x: 'max-content',
                },
                columns: [
                    {
                        property: 'pictureUrl',
                        width: 150,
                        displayConfig: {
                            style: {
                                width: 50,
                                height: 50,
                                border: '1px solid #eee',
                            },
                        },
                    },
                    ...getLanguageColumns('name'),
                    ...getLanguageColumns('brandName'),
                    { property: 'csku' },
                    {
                        property: 'offlineCategoryName',
                        displayConfig: {
                            format: (val: any[]) => (val || []).join('/'),
                        },
                    },
                    { property: 'sourceType' },
                ],
                actionColumn: {
                    title: '<<actions>>',
                    fixed: 'right',
                    items: [
                        {
                            text: '<<view>>',
                            type: 'component',
                            component: 'TableActionStateful',
                            config: {
                                actionType: 'link',
                                renderConfig: (row: any, props: any) => {
                                    return {
                                        path: `/productList/${
                                            //@ts-ignore
                                            Source2Router[row.sourceType]
                                        }/detail/${row.id}`,
                                    };
                                },
                            },
                        },
                    ],
                },
            },
        ],
    },
};

export const config: Config = {
    entities: {
        categoryProduct: {
            apiPath: '/loader/dc-goods/admin/cskus/pageByCategory',
            properties: entityProperties,
            filters: entityFilters,
        },
        displayCategoryProduct: {
            apiPath: '/loader/dc-goods/admin/cskus/pageByDisplayCategory',
            properties: entityProperties,
            filters: entityFilters,
        },
    },
    components: {
        CategoryProductManagement: {
            component: 'Card',
            ...componentConfig,
            entity: 'categoryProduct',
        },
        DisplayCategoryProduct: {
            component: 'Card',
            ...cloneDeep(componentConfig),
            entity: 'displayCategoryProduct',
        },
    },
};

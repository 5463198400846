import { language } from '@comall-backend-builder/core/lib/services';
import { message } from 'antd';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { tableMixin, flexibleFilterForm, formFooter } from '@/configs/mixin';
import { STATUS_OPTIONS } from '@/constants';

export const config: Config = {
    entities: {
        users: {
            apiPath: '/dc-user/admin/users',
            properties: {
                realName: {
                    type: 'string',
                    displayName: '<<privilegeManage.users.realName>>',
                    controlConfig: {
                        placeholder: '<<privilegeManage.users.realNamePlaceholder>>',
                        maxLength: 50,
                    },
                    rules: [
                        { required: true, whitespace: true },
                        {
                            min: 1,
                            max: 50,
                            message: '<<privilegeManage.users.realNameNoLength>>',
                        },
                    ],
                },
                email: {
                    type: 'string',
                    displayName: '<<privilegeManage.users.email>>',
                    controlConfig: {
                        placeholder: '<<privilegeManage.users.emailPlaceholder>>',
                    },
                    rules: [
                        {
                            required: true,
                            message: '<<privilegeManage.users.emailNoEmpty>>',
                        },
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (!value) return new Error();
                                const EMAIL_REG = /^([a-z0-9A-Z]+[-_.]?)+[a-z0-9A-Z]@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\.)+(([a-zA-Z]{2,})|(\.\w+))$/;
                                if (
                                    (value !== undefined && !EMAIL_REG.test(value)) ||
                                    value.length > 50
                                ) {
                                    return new Error(
                                        services.language.getText(
                                            'privilegeManage.users.emailError'
                                        )
                                    );
                                } else {
                                    callback();
                                }
                            },
                        },
                    ],
                },
                roleName: {
                    type: 'string',
                    displayName: '<<privilegeManage.users.roles>>',
                },
                status: {
                    type: 'string.status',
                    displayName: '<<privilegeManage.users.status>>',
                },
                onlineStoreIds: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<privilegeManage.users.ownOnlineStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/allTree',
                    },
                    controlConfig: {
                        showArrow: true,
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                    rules: [{ required: true }],
                },
                retailStoreIds: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<privilegeManage.users.retailStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/retailStore/findStoreOrganizations',
                    },
                    controlConfig: {
                        showArrow: true,
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                    // rules: [{ required: true }],
                },
                roleIds: {
                    type: 'array.optionIds.select',
                    displayName: '<<privilegeManage.users.roles>>',
                    options: [],
                    source: {
                        apiPath: '/dc-user/admin/roles/role_list',
                    },
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                    rules: [{ required: true }],
                },
                // channelIds: {
                //     type: 'array.optionIds.select',
                //     displayName: '<<privilegeManage.users.ownChannel>>',
                //     options: [],
                //     source: {
                //         apiPath: '/dc-master-data/admin/distribution_channels',
                //     },
                //     controlConfig: {
                //         allowClear: true,
                //         placeholder: '<<defaultPlaceholderSelect>>',
                //     },
                //     rules: [{ required: true }],
                // },
                loginPassword: {
                    type: 'string.newPassword',
                    displayName: '<<privilegeManage.users.password>>',
                    controlConfig: {
                        placeholder: '<<privilegeManage.users.loginPasswordPlaceholder>>',
                        maxLength: 20,
                    },
                    rules: [
                        { required: true, whitespace: true },
                        {
                            pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#@!~%^&*.\-`$_]).{8,20}/,
                            message: '<<privilegeManage.users.passwordRule>>',
                        },
                    ],
                },
                confirmPassword: {
                    type: 'string.newPassword',
                    displayName: '<<privilegeManage.users.confirmPassword>>',
                    controlConfig: {
                        placeholder: '<<privilegeManage.users.confirmPasswordPlaceholder>>',
                        maxLength: 20,
                    },
                    rules: [
                        { required: true, whitespace: true },
                        {
                            pattern: /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#@!~%^&*.\-`$_]).{8,20}/,
                            message: '<<privilegeManage.users.passwordRule>>',
                        },
                    ],
                },
                merchantIds: {
                    type: 'array.optionIds.select',
                    displayName: '<<privilegeManage.users.ownMerchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-goods/admin/merchant',
                    },
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                    rules: [{ required: true }],
                },
            },
            filters: {
                keyword: {
                    type: 'string',
                    displayName: '<<privilegeManage.users.keyword>>',
                    controlConfig: {
                        placeholder: '<<privilegeManage.users.keywordPlaceHolder>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<privilegeManage.users.status>>',
                    options: [
                        { id: '1', name: '<<statusValue.enabled>>' },
                        { id: '0', name: '<<statusValue.disabled>>' },
                    ],
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                        style: { width: '100%' },
                    },
                },
                roleId: {
                    type: 'string.options.select',
                    displayName: '<<privilegeManage.users.roles>>',
                    options: [],
                    source: {
                        apiPath: '/dc-user/admin/roles/role_list',
                    },
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                // channelIds: {
                //     type: 'array.optionIds.select',
                //     displayName: '<<privilegeManage.users.channel>>',
                //     options: [],
                //     source: {
                //         apiPath: '/dc-master-data/admin/distribution_channels',
                //     },
                //     controlConfig: {
                //         allowClear: true,
                //         showArrow: true,
                //         placeholder: '<<defaultPlaceholderSelect>>',
                //     },
                // },
                // organizationId: {
                //     type: 'string.treeNode.cascader',
                //     displayName: '<<privilegeManage.users.organization>>',
                //     controlConfig: {
                //         placeholder: '<<defaultPlaceholderSelect>>',
                //     },
                //     options: [],
                //     source: {
                //         apiPath: '/dc-master-data/admin/organizations/findAllByLoginUser',
                //     },
                // },
                onlineStoreIds: {
                    type: 'array.treeNodeIds.tree',
                    displayName: '<<privilegeManage.users.onlineStore>>',
                    options: [],
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/allTree',
                    },
                    controlConfig: {
                        showArrow: true,
                        allowClear: true,
                        treeCheckable: true,
                        treeNodeFilterProp: 'name',
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                // retailStoreIds: {
                //     type: 'array.treeNodeIds.tree',
                //     displayName: '<<privilegeManage.users.retailStore>>',
                //     options: [],
                //     source: {
                //         apiPath: '/loader/dc-store/admin/retailStore/findStoreOrganizations',
                //     },
                //     controlConfig: {
                //         allowClear: true,
                //         treeCheckable: true,
                //         treeNodeFilterProp: 'name',
                //         placeholder: '<<defaultPlaceholderSelect>>',
                //     },
                // },
            },
        },
    },
    components: {
        UsersContainer: {
            component: 'Viewport',
        },
        UsersPage: {
            component: 'Card',
            entity: 'users',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    { component: 'UsersFilter' },
                    {
                        component: 'Button',
                        text: '<<privilegeManage.users.addUser>>',
                        icon: 'plus',
                        type: 'primary',
                        route: '/privilege/users/add',
                        style: {
                            marginBottom: '15px',
                            width: 'fit-content',
                        },
                        privilege: {
                            path: 'privilege.user.add',
                            level: 'full',
                        },
                    },
                    { component: 'UsersTable' },
                ],
                privilege: {
                    path: 'privilege.user.view_list',
                    level: 'full',
                },
            },
        },
        UsersFilter: {
            ...flexibleFilterForm,
            labelCol: {},
            fields: [
                { property: 'keyword' },
                { property: 'status' },
                { property: 'roleId' },
                // { property: 'organizationId' },
                // { property: 'channelIds', displayName: '<<privilegeManage.users.channel>>' },
                {
                    property: 'onlineStoreIds',
                    displayName: '<<privilegeManage.users.onlineStore>>',
                },
                // { property: 'retailStoreIds' },
            ],
        },
        UsersTable: {
            ...tableMixin,
            scroll: {
                x: 1300,
            },
            columns: [
                { property: 'realName' },
                { property: 'email' },
                { property: 'roleName' },
                {
                    property: 'status',
                    width: 150,
                    displayConfig: {
                        statusConfig: STATUS_OPTIONS,
                    },
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                width: 200,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<statusValue.disable>>',
                        config: {
                            actionType: 'component',
                            component: 'TableActionToggleStatus',
                            renderConfig: (row: any, props: any) => {
                                if (row.admin) {
                                    return {
                                        disabled: true,
                                    };
                                } else {
                                    return {
                                        entity: props.entity,
                                        row,
                                        statusApi: {
                                            '0': '/dc-user/admin/users/{{row.id}}/enable',
                                            '1': '/dc-user/admin/users/{{row.id}}/disable',
                                        },
                                        statusText: {
                                            '0': '<<statusActions.enable>>',
                                            '1': '<<statusActions.disable>>',
                                        },
                                    };
                                }
                            },
                        },
                        privilege: {
                            path: 'privilege.user.disable',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<edit>>',
                        config: {
                            actionType: 'link',
                            renderConfig: (row: any) => {
                                if (row.admin) {
                                    return {
                                        disabled: true,
                                    };
                                } else {
                                    return {
                                        path: `/privilege/users/edit/${row.id}`,
                                    };
                                }
                            },
                        },
                        privilege: {
                            path: 'privilege.user.view_detail',
                            level: 'full',
                        },
                    },
                    // {
                    //     type: 'component',
                    //     component: 'TableActionStateful',
                    //     text: '<<delete>>',
                    //     config: {
                    //         actionType: 'delete',
                    //         renderConfig: (row: any) => {
                    //             if (row.admin) {
                    //                 return {
                    //                     disabled: true,
                    //                 };
                    //             }
                    //         },
                    //     },
                    //     privilege: {
                    //         path: 'privilege.user.delete',
                    //         level: 'full',
                    //     },
                    // },
                    // {
                    //     type: 'component',
                    //     component: 'TableActionChangePassword',
                    //     privilege: {
                    //         path: 'privilege.user.reset_password',
                    //         level: 'full',
                    //     },
                    // },
                ],
            },
        },
        UsersAddPage: {
            component: 'Card',
            content: {
                component: 'UsersAddForm',
            },
        },
        UsersAddForm: {
            component: 'CreateFormPlus',
            entity: 'users',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            onValidate: (value: any) => {
                if (value.loginPassword !== value.confirmPassword) {
                    message.error(language.getText('privilegeManage.users.confirmPasswordRule'));
                    return Promise.reject(value);
                }
                return true;
            },
            fields: [
                {
                    property: 'realName',
                },
                {
                    property: 'email',
                },
                // {
                //     property: 'loginPassword',
                // },
                // {
                //     property: 'confirmPassword',
                // },
                // {
                //     property: 'organizationIds',
                // },
                {
                    property: 'merchantIds',
                },
                {
                    property: 'onlineStoreIds',
                },
                // {
                //     property: 'retailStoreIds',
                // },
                // {
                //     property: 'channelIds',
                // },
                {
                    property: 'roleIds',
                },
            ],
        },
        UsersEditPage: {
            component: 'Card',
            content: {
                component: 'UsersEditForm',
            },
        },
        UsersEditForm: {
            component: 'ModifyForm',
            entity: 'users',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 8,
            footer: formFooter,
            submitSuccessBehavior: {
                route: 'goBack',
            },
            fields: [
                {
                    property: 'realName',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'email',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'merchantIds',
                },
                // {
                //     property: 'channelIds',
                // },
                {
                    property: 'onlineStoreIds',
                },
                // {
                //     property: 'retailStoreIds',
                // },
                {
                    property: 'roleIds',
                },
            ],
        },
    },
};

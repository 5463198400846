import { Config } from '@comall-backend-builder/core/lib/parser';
import { createPageLayout, flexibleFilterForm, tableMixin } from '../mixin';
import { buildForm } from './popup-ads-form';
import {
    ACTIVITY_STATUS_OPTIONS,
    DISPLAY_LOCATION_OPTIONS,
    SEND_FREQUENCY_OPTIONS,
} from '@/constants/popup-ads';

export const config: Config = {
    entities: {
        popupAds: {
            apiPath: '/loader/admin/advertisements',
            properties: {
                id: {
                    type: 'string',
                },
                activityName: {
                    type: 'string',
                    displayName: '<<popupAds.activityName>>',
                },
                displayTime: {
                    type: 'object.dateTimeRange',
                    displayName: '<<popupAds.displayTime>>',
                },
                displayStartTime: {
                    type: 'string',
                    displayName: '<<popupAds.displayStartTime>>',
                },
                displayEndTime: {
                    type: 'string',
                    displayName: '<<popupAds.displayEndTime>>',
                },
                onlineStoreId: {
                    type: 'string.treeNode.cascader',
                    displayName: '<<popupAds.displayStore>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                },
                onlineStoreName: {
                    type: 'string',
                    displayName: '<<common.onlineStoreName>>',
                },
                displayLocation: {
                    type: 'string.options.select',
                    displayName: '<<popupAds.displayLocation>>',
                    options: DISPLAY_LOCATION_OPTIONS,
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                },
                brandId: {
                    type: 'string.options.search',
                    displayName: '<<popupAds.selectBrand>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        source: {
                            apiPath: '/dc-goods/admin/brands/list',
                        },
                        optionFilterProp: 'label',
                        showSearch: true,
                    },
                },
                categoryId: {
                    type: 'string.treeNode.tree',
                    displayName: '<<popupAds.selectCategory>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                },
                merchantId: {
                    type: 'string.options.select',
                    displayName: '<<popupAds.selectMerchant>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                    source: {
                        apiPath: '/loader/dc-goods/admin/store/freight/merchant/findFreightList',
                    },
                },
                sendFrequency: {
                    type: 'string.options.select',
                    displayName: '<<popupAds.sendFrequency>>',
                    options: SEND_FREQUENCY_OPTIONS,
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                    },
                },
                priority: {
                    type: 'number.integer',
                    displayName: '<<popupAds.priority>>',
                    controlConfig: {
                        min: 0,
                        max: 99999999,
                    },
                },
                popupAdPc: {
                    type: 'array.urlImage',
                    displayName: '<<popupAds.popupAdPc>>',
                    controlConfig: {
                        fileName: 'fileName',
                        uploadType: 'single',
                        multiple: false,
                        mode: 'picture-card',
                        maxCount: 1,
                        maxSize: 5 * 1024,
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    },
                },
                popupAdMob: {
                    type: 'array.urlImage',
                    displayName: '<<popupAds.popupAdMob>>',
                    controlConfig: {
                        fileName: 'fileName',
                        uploadType: 'single',
                        multiple: false,
                        mode: 'picture-card',
                        maxCount: 1,
                        maxSize: 5 * 1024,
                        uploadUrl:
                            ENV.API_ROOT + '/dc-file/oss/image_locations/1/images?rename=true',
                    },
                },
                redirectUrl: {
                    type: 'string',
                    displayName: '<<popupAds.redirectUrl>>',
                    controlConfig: {
                        maxLength: 200,
                    },
                },
                status: {
                    type: 'string',
                    displayName: '<<common.status>>',
                },
                activityStatus: {
                    type: 'string.status',
                    displayName: '<<popupAds.activityStatus>>',
                },
                lastModifyUserName: {
                    type: 'string',
                    displayName: '<<popupAds.lastModifyUser>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<popupAds.lastModifyTime>>',
                },
            },
            filters: {
                activityName: {
                    type: 'string',
                    displayName: '<<popupAds.activityName>>',
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                    },
                },
                displayLocation: {
                    type: 'string.options.select',
                    displayName: '<<popupAds.displayLocation>>',
                    options: DISPLAY_LOCATION_OPTIONS,
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        allowClear: true,
                    },
                },
                activityStatus: {
                    type: 'string.options.select',
                    displayName: '<<popupAds.activityStatus>>',
                    options: ACTIVITY_STATUS_OPTIONS,
                    controlConfig: {
                        placeholder: '<<common.placeInput>>',
                        allowClear: true,
                    },
                },
                onlineStoreId: {
                    type: 'string.treeNode.cascader',
                    displayName: '<<popupAds.onlineStoreId>>',
                    options: [],
                    controlConfig: {
                        placeholder: '<<common.placeSelect>>',
                        allowClear: true,
                    },
                    source: {
                        apiPath: '/loader/dc-store/admin/onlineStore/own',
                    },
                },
            },
        },
    },
    components: {
        PopupAdsPage: {
            entity: 'popupAds',
            ...createPageLayout([
                {
                    ...flexibleFilterForm,
                    fields: [
                        {
                            property: 'activityName',
                        },
                        {
                            property: 'displayLocation',
                        },
                        {
                            property: 'activityStatus',
                        },
                        {
                            property: 'onlineStoreId',
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        display: 'block',
                        marginBottom: 15,
                    },
                    items: [
                        {
                            component: 'Button',
                            type: 'primary',
                            icon: 'plus',
                            text: '<<popupAds.add>>',
                            route: '/popup-ads/add',
                            privilege: {
                                path: 'finish.advertisement_menu_bar.advertisements_add',
                                level: 'full',
                            },
                        },
                    ],
                },
                {
                    component: 'PopupAdsTable',
                    privilege: {
                        path: 'finish.advertisement_menu_bar.advertisements',
                        level: 'full',
                    },
                },
            ]),
        },
        PopupAdsAddPage: {
            component: 'Card',
            entity: 'popupAds',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('add')],
            },
        },
        PopupAdsEditPage: {
            component: 'Card',
            entity: 'popupAds',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [buildForm('edit')],
            },
        },
        PopupAdsTable: {
            ...tableMixin,
            columns: [
                {
                    property: 'activityName',
                },
                {
                    property: 'onlineStoreName',
                },
                {
                    property: 'displayLocation',
                },
                {
                    property: 'priority',
                },
                {
                    property: 'displayStartTime',
                },
                {
                    property: 'displayEndTime',
                },
                {
                    property: 'activityStatus',
                    displayConfig: {
                        statusConfig: ACTIVITY_STATUS_OPTIONS,
                    },
                },
                {
                    property: 'lastModifyUserName',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<actions>>',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'TableActionToggleStatus',
                        text: '<<disable>>',
                        config: {
                            statusApi: {
                                DISABLE: '/dc-cms-api/admin/advertisements/enable/{{row.id}}',
                                ENABLE: '/dc-cms-api/admin/advertisements/disable/{{row.id}}',
                            },
                            statusText: {
                                DISABLE: '<<enable>>',
                                ENABLE: '<<disable>>',
                            },
                            linkProperty: 'status',
                            errorStatus: 'ENABLE',
                        },
                        privilege: {
                            path: 'finish.advertisement_menu_bar.advertisements_enable',
                            level: 'full',
                        },
                    },
                    {
                        text: '<<edit>>',
                        type: 'link',
                        path: '/popup-ads/edit/{{row.id}}',
                        privilege: {
                            path: 'finish.advertisement_menu_bar.advertisements_edit',
                            level: 'full',
                        },
                    },
                    {
                        type: 'component',
                        component: 'TableActionStateful',
                        text: '<<common.delete>>',
                        config: {
                            actionType: 'delete',
                            source: {
                                apiPath: '/dc-cms-api/admin/advertisements',
                            },
                        },
                        privilege: {
                            path: 'finish.advertisement_menu_bar.advertisements_delete',
                            level: 'full',
                        },
                    },
                ],
            },
        },
    },
};

import React, { useState, useMemo } from 'react';
import { Modal } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

import { OrderInfoProductProps } from '.';
import './index.less';

/**
 * 校验金额是否大于0
 * @param price 金额
 * @returns boolean
 */
const checkPriceValidate: (price: string) => boolean = (price) => {
    return !isNaN(Number(price)) && Number(price) > 0;
};

export default (props: OrderInfoProductProps) => {
    const [loading, setLoading] = useState(false);

    const amountMetas = useMemo<any>(() => {
        let checkout = props.checkoutData;
        return [
            {
                label: language.getText('order.checkout.totalAmount'),
                value: `HKD ${checkout.goodsAmount}`,
                show: true,
            },
            {
                label: language.getText('offlineOrder.amountConfirm.freight'),
                value: checkout.freightFeeAmount ? `+HKD ${checkout.freightFeeAmount}` : '-',
                show: true,
            },

            {
                label: language.getText('offlineOrder.amountConfirm.promotionAmount'),
                value: `-HKD ${checkout.promotionAmount}`,
                show: checkPriceValidate(checkout.promotionAmount),
            },
            {
                label: language.getText('offlineOrder.amountConfirm.couponAmount'),
                value: `-HKD ${checkout.couponAmount}`,
                show: checkPriceValidate(checkout.couponAmount),
            },
            {
                label: language.getText('offlineOrder.amountConfirm.freightFeeDiscountAmount'),
                value: `-HKD ${checkout.freightFeeDiscountAmount}`,
                show: checkPriceValidate(checkout.freightFeeDiscountAmount),
            },
            {
                label: language.getText('offlineOrder.amountConfirm.pointAmount'),
                value: `-HKD ${checkout.pointAmount}`,
                show: checkPriceValidate(checkout.pointAmount),
            },
            {
                label: language.getText('offlineOrder.amountConfirm.voucherAmount'),
                value: `-HKD ${checkout.voucherAmount}`,
                show: checkPriceValidate(checkout.voucherAmount),
            },
        ];
    }, [props.checkoutData]);

    return (
        <Modal
            visible={props.visible}
            onCancel={props.onCancel}
            closable={false}
            width={500}
            cancelText={language.getText('common.cancel')}
            okText={language.getText('offlineOrder.amountConfirm.onText')}
            confirmLoading={loading}
            onOk={async () => {
                setLoading(true);
                props.onOk().finally(() => {
                    setLoading(false);
                });
            }}
        >
            <div className='offline-order-submit-confirm'>
                {amountMetas.map((item: any) => {
                    return (
                        item.show && (
                            <div className='offline-order-amount-item'>
                                <div className='offline-order-amount-item-label'>
                                    {item.label}：
                                </div>
                                <div className='offline-order-amount-item-value'>{item.value}</div>
                            </div>
                        )
                    );
                })}

                <div className='offline-order-submit-confirm-total'>
                    <span>{language.getText('offlineOrder.amountConfirm.actualAmount')}：</span>
                    <span className='offline-order-submit-confirm-total-amount'>
                        HKD {props.checkoutData.payableAmount}
                    </span>
                </div>
            </div>
        </Modal>
    );
};

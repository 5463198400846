/**
 * 商品上下架
 */

import { cloneDeep } from 'lodash';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { flexibleFilterForm, getLanguageProperties } from '../mixin';

import { PRODUCT_SOURCE_TYPE_OPTIONS } from '@/constants';
const entityProperties = {
    onlineStoreName: {
        type: 'string',
        displayName: '<<productShelf.onlineStoreName>>',
    },
    productNameLanguage: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'productShelf.productName') },
    },
    csku: {
        type: 'string',
        displayName: 'SKU',
    },
    rsku: {
        type: 'string',
        displayName: 'GoodsId',
    },
    styleId: {
        type: 'string',
        displayName: 'StyleId',
    },
    saleStatus: {
        type: 'string.converter',
        displayName: '<<productShelf.saleStatus>>',
    },
    abnormalReason: {
        type: 'string',
        displayName: '<<productShelf.reason>>',
    },
    brandNameLanguage: {
        type: 'object.subForm',
        properties: { ...getLanguageProperties('string', 'productShelf.brandName') },
    },
    categories: {
        type: 'string',
        displayName: '<<productShelf.standardCategory>>',
    },
    symbol: {
        type: 'string',
        displayName: '<<productShelf.ISOCode>>',
    },
    price: {
        type: 'string',
        displayName: '<<productShelf.price>>',
    },
    sourceType: {
        type: 'string.options.select',
        displayName: '<<productShelf.productSource>>',
        options: PRODUCT_SOURCE_TYPE_OPTIONS,
    },
    availableStock: {
        type: 'string',
        displayName: '<<productShelf.availableStock>>',
    },
    safeQuantity: {
        type: 'string',
        displayName: '<<productShelf.safeQuantity>>',
    },
    orderQuantity: {
        type: 'string',
        displayName: '<<productShelf.orderQuantity>>',
    },
    stockQuantity: {
        type: 'string',
        displayName: '<<productShelf.stockQuantity>>',
    },
    isShowFront: {
        type: 'string.options.select',
        displayName: '<<productShelf.isShowFront>>',
        options: [
            { id: 'true', name: services.language.getText('yes') },
            { id: 'false', name: services.language.getText('no') },
        ],
    },
    notShowReason: {
        type: 'string',
        displayName: '<<productShelf.notShowReason>>',
    },
};
const entityFilters = {
    keyword: {
        type: 'string',
        displayName: '<<productShelf.keyword>>',
    },
    csku: {
        type: 'string',
        displayName: 'SKU',
    },
    sourceType: {
        type: 'string.options.select',
        displayName: '<<productShelf.productSource>>',
        options: PRODUCT_SOURCE_TYPE_OPTIONS,
    },
    brandId: {
        type: 'array.options.autoComplete',
        displayName: '<<productShelf.brandName>>',
        options: [],
        source: {
            apiPath: '/dc-goods/admin/brands/list?page_size=20',
        },
    },
    isShowFront: {
        type: 'string.options.select',
        displayName: '<<productShelf.isShowFront>>',
        options: [
            { id: 'true', name: services.language.getText('yes') },
            { id: 'false', name: services.language.getText('no') },
        ],
    },
    merchantId: {
        type: 'string.options.select',
        displayName: '<<productList.merchantName>>',
        options: [],
        controlConfig: {
            placeholder: '<<common.placeSelect>>',
        },
        source: {
            apiPath: '/loader/dc-goods/admin/merchant/byUser',
        },
    },
};

export const config: Config = {
    entities: {
        productShelfLogs: {
            apiPath: '/dc-goods/admin/productscrolllog',
            properties: {
                type: {
                    type: 'string.converter',
                    displayName: '<<productShelf.logsTable.operateType>>',
                },
                createUser: {
                    type: 'string',
                    displayName: '<<productShelf.logsTable.operatePerson>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<productShelf.logsTable.operateTime>>',
                },
                reason: {
                    type: 'string',
                    displayName: '<<productShelf.logsTable.reason>>',
                },
            },
            // filters为空也要声明，否则调用 Entity.filtersChange 会报错;
            filters: {},
        },
        productOnShelf: {
            apiPath: '/loader/dc-goods/admin/productscroll',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
        productOffShelf: {
            apiPath: '/loader/dc-goods/admin/productscroll',
            properties: cloneDeep(entityProperties),
            filters: cloneDeep(entityFilters),
        },
    },
    components: {
        ProductShelfContainer: {
            component: 'Viewport',
        },
        ProductShelfPage: {
            component: 'Card',
            entities: [
                { name: 'productShelfLogs', entityName: 'productShelfLogs' },
                { name: 'productOnShelf', entityName: 'productOnShelf' },
                { name: 'productOffShelf', entityName: 'productOffShelf' },
            ],
            content: {
                component: 'ProductShelfList',
                filterConfig: {
                    component: 'ProductShelfFilter',
                    privilege: {
                        path: 'goods.productscroll.view_list',
                        level: 'full',
                    },
                },
            },
        },
        ProductShelfFilter: {
            ...flexibleFilterForm,
            fields: [
                {
                    property: 'keyword',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<productShelf.keywordPlaceholder>>',
                    },
                },
                {
                    property: 'csku',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<cskuPlaceHolder>>',
                    },
                },

                {
                    property: 'brandId',
                    controlConfig: {
                        showArrow: true,
                        showSearch: true,
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'sourceType',
                    controlConfig: {
                        showSearch: true,
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'isShowFront',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
                {
                    property: 'merchantId',
                    controlConfig: {
                        allowClear: true,
                        placeholder: '<<defaultPlaceholderSelect>>',
                    },
                },
            ],
        },
    },
};
